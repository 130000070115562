import React from "react";
import { Switch, Route ,Redirect  } from "react-router-dom";
import Suppliers from "../pages/Suppliers";
import FormLibrary from "../pages/FormLibrary";
import FormBuilder from "../pages/FormBuilder";
import QuestionLibrary from "../pages/QuestionLibrary";
import { ROOT,DASHBOARD,ADMIN_FORM_BUILDER, ADMIN_CLIENTS, ADMIN_SUPPLIERS, ADMIN_QUESTION_LIBRARY,ADMIN_FORM_LIBRARY,ADMIN_FORM_LIBRARY_DETAILS,
  ADMIN_CLIENT_DETAILS, ADMIN_SUPPLIERS_DETAILS ,ADMIN_SUPPLIERS_ANSWERS_RESPONSE ,ADMIN_SENT_FORMS, UPLOAD_FORMS, ADMIN_ADD_FROM_LIBRARY} from "./constants";
import Dashboard from "../components/Dashboard";
import '../assets/css/style.css';
import '../assets/css/responsive.css';
import Clients from "../pages/Clients";
import ClientDetails from "../pages/Clients/ClientDetails";
import BasicData from "../pages/Suppliers/BasicData";
import AdminFormResponse from "../pages/Suppliers/adminFormResponse";
import PendingForms from "../pages/PendingForms";
import UploadForms from "../pages/FormLibrary/uploadFile";
export const AdminRouterConfig = () => {
  return (
    <div>
      <Switch>
        {/* List all public routes here */}
        <Route exact path={ROOT} >
             <Redirect to={DASHBOARD} />
        </Route>
        <Route exact path={DASHBOARD} component={Dashboard} />
        {/* Admin Routes Start */}
        <Route exact path={ADMIN_CLIENTS} component={Clients} />
        <Route exact path={ADMIN_SUPPLIERS} component={Suppliers} />
        <Route exact path={ADMIN_QUESTION_LIBRARY} component={QuestionLibrary} />
        <Route exact path={ADMIN_FORM_LIBRARY} component={FormLibrary} />
        <Route exact path={ADMIN_FORM_BUILDER} component={FormBuilder} />
        <Route exact path={ADMIN_FORM_LIBRARY_DETAILS} component={FormBuilder} />
        <Route exact path={ADMIN_SENT_FORMS} component={PendingForms} />
        <Route exact path={ADMIN_CLIENT_DETAILS} component={ClientDetails} />
        <Route exact path={ADMIN_SUPPLIERS_DETAILS} component={BasicData} />
        <Route exact path={ADMIN_SUPPLIERS_ANSWERS_RESPONSE} component={AdminFormResponse} />
        <Route exact path={UPLOAD_FORMS} component={UploadForms} />
        <Route exact path={ADMIN_ADD_FROM_LIBRARY} component={QuestionLibrary} />
        
        <Route exact path="*">
          <Redirect to={DASHBOARD} />
        </Route>
      </Switch>
    </div>
  );
};
