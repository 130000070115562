import React, { useState } from "react";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import CreateForm from "./createForm";
import './index.css';
import {FORM_BUILDER } from "../../common/constants";
import Footer from "../Footer";

const FormBuilder = (props) => {
    const [sideMenu, setSideMenu] = useState(true);
    const onSideMenu = (value) => {
      setSideMenu(value);
    };
  return (
    <div className="page-wrapper">
      <Layout onClick={onSideMenu} />
      <div
        className={`main-content d-flex flex-column ${
          sideMenu ? "" : "hide-sidemenu"
        }`}
      >
        <div className="main-content-header">
          <BreadCrumb />
        </div>
          <CreateForm redirectFrom={FORM_BUILDER}/>
          <div className="flex-grow-1"></div>
                    <Footer /> 
      </div>
    </div>
  );
};

export default FormBuilder;
