import React, { useState } from "react";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import BasicDetails from "./basicDetails";
const Profile = (props) => {
  const [sideMenu, setSideMenu] = useState(true);
  const onSideMenu = (value) => {
    setSideMenu(value);
  };


  return (
    <div className="page-wrapper">
    <Layout onClick={onSideMenu} />
    <div className={`main-content d-flex flex-column ${sideMenu ? "" : "hide-sidemenu"  }`}>
      <div className="main-content-header">
        <BreadCrumb />
        
      </div>
      <BasicDetails />
      {/* <BasicData /> */}
    </div>
  </div> 
    
  );
};

export default Profile;
