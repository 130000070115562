import React, { useState, useRef } from "react";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import Footer from "../Footer";
import { ERROR, ToastMessage } from "../../common/ToastMessage";
import { uploadFormsAction } from "../../redux/actions/formLibraryAction";
import { useSelector, useDispatch } from "react-redux";
const UploadForms = (props) => {
  const [sideMenu, setSideMenu] = useState(true);
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const onSideMenu = (value) => {
    setSideMenu(value);
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = async(event) => {
      console.log(event.target,event.target.files[0])
    const fileUploaded = event.target.files[0];
    var extension = fileUploaded.name.split(".").pop();
    if (extension !== "xlsx" && extension != "xls") {
      ToastMessage(
        ERROR,
        "File type not supported. Please upload a xlsx file."
      );
      event.target.value = "";
      return;
    }
    const data = new FormData();
    data.append("file", fileUploaded, fileUploaded.name);
    console.log(data)
    // await dispatch(uploadFormsAction(data));
    event.target.value = "";
  };
  return (
    <div className="page-wrapper">
      <Layout onClick={onSideMenu} />
      <div
        className={`main-content d-flex flex-column ${
          sideMenu ? "" : "hide-sidemenu"
        }`}
      >
        <div className="main-content-header">
          <BreadCrumb />
        </div>
        <div>
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            <button
              type="file"
              name="file"
              className="btn btn-primary upstream-buttons"
              onClick={handleClick}
            >
              <span>
                <i className="fa fa-upload" />
                &nbsp; Upload Input
              </span>
            </button>
            <input
              type="file"
              name="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div className="flex-grow-1"></div>
        <Footer />
      </div>
    </div>
  );
};

export default UploadForms;
