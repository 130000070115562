import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import * as Icon from "react-feather";
import "./SideMenu.css";
import InviteSupplier from "../../../pages//Suppliers/InviteSupplier";
import { useSelector } from "react-redux";
import InviteClient from "../../../pages/Clients/InviteClient";
import { SUPPLIER } from "../../../common/constants";
const SideMenuLight = (props) => {
  const { userDetails } = useSelector((state) => state.user);
  const [isInviteSupplier, setIsInviteSupplier] = useState(false);
  const [isInviteClient, setIsInviteClient] = useState(false);
  const handleInviteSupplier = () => {
    setIsInviteSupplier(true);
  };
  const closeModal = () => {
    setIsInviteSupplier(false);
  };
  const handleInviteClient = () => {
    setIsInviteClient(true);
    setIsInviteClient(true);
  };
  return (
    <div
      className={`sidemenu-area sidemenu-light ${
        props.sideMenu ? "sidemenu-toggle" : ""
      }`}
    >
      {/* {isInviteSupplier ? (
        <InviteSupplier isOpen={isInviteSupplier} handleClose={closeModal} />
      ) : null} */}
      {isInviteClient ? (
        <InviteClient isOpen={isInviteClient} handleClose={closeModal} />
      ) : null}
      <Navbar className={`sidemenu ${props.sideMenu ? "hide-nav-title" : ""}`}>
        <Navbar.Collapse>
          <Nav>
            {userDetails && userDetails.role === "Client" ? (
              <>
                {/* Client dashboard routes start */}
                <NavLink to="/dashboard" className="nav-link">
                  <i className="fa fa-tachometer icon"></i>
                  <span className="title">Dashboard</span>
                </NavLink>
                <NavLink to="/client/suppliers" className="nav-link">
                  <Icon.User className="icon" />
                  <span className="title">My Suppliers</span>
                </NavLink>

                <NavLink to="/client/formLibrary" className="nav-link">
                  <Icon.FileText className="icon" />
                  <span className="title">Form Library</span>
                </NavLink>

                <NavLink to="/client/questionLibrary" className="nav-link">
                  <Icon.HelpCircle className="icon" />
                  <span className="title">Question Library</span>
                </NavLink>
                <NavLink to="/client/pendingForms" className="nav-link">
                  <Icon.HelpCircle className="icon" />
                  <span className="title">Pending Forms</span>
                </NavLink>
                <NavLink to="/client/completedForms" className="nav-link">
                  <Icon.CheckCircle className="icon" />
                  <span className="title">Completed Forms</span>
                </NavLink>
                {/* <div className="nav-link" onClick={handleInviteSupplier}>
                  <Icon.UserPlus className="icon" />
                  <span className="title">Invite Suppliers</span>
                </div> */}
                {/* <div className="nav-link" onClick={handleInviteClient}>
                  <Icon.UserPlus className="icon" />
                  <span className="title">Invite Client</span>
                </div> */}
                {/* <NavDropdown
                  title={
                    <div className="dropdown-title">
                      <Icon.TrendingUp className="icon" />
                      <span className="title">
                        Reports
                        <Icon.ChevronRight className="icon fr" />
                      </span>
                    </div>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavLink
                    to="/client/reports/complaintPercentage"
                    className="dropdown-item"
                    // className="nav-link"
                  >
                    <i
                      className="fa fa-comments icon-colors"
                      aria-hidden="true"
                    ></i>
                    &nbsp;&nbsp;
                    <span className="title"> Complaint Percentage </span>
                  </NavLink>
                  <NavLink
                    to="/client/reports/formDueDate"
                    className="dropdown-item"
                  >
                    <i
                      className="fa fa-calendar icon-colors"
                      aria-hidden="true"
                    ></i>
                    &nbsp;&nbsp;
                    <span className="title"> From Due Date </span>
                  </NavLink>
                  <NavLink
                    to="/client/reports/riskScore"
                    className="dropdown-item"
                  >
                    <i
                      className="fa fa-exclamation-triangle icon-colors"
                      aria-hidden="true"
                    ></i>
                    &nbsp;&nbsp;
                    <span className="title"> Risk Score </span>
                  </NavLink>
                  <NavLink
                    to="/client/reports/auditStatus"
                    className="dropdown-item"
                  >
                    <i
                      className="fa fa-history icon-colors"
                      aria-hidden="true"
                    ></i>{" "}
                    &nbsp;&nbsp;
                    <span className="title"> Audit Status </span>
                  </NavLink>
                  <NavLink to="/client/reports/forms" className="dropdown-item">
                    <i
                      className="fa fa-wpforms icon-colors"
                      aria-hidden="true"
                    ></i>{" "}
                    &nbsp;&nbsp;
                    <span className="title"> Forms </span>
                  </NavLink>
                </NavDropdown> */}
                {/* Client dashboard routes end */}
              </>
            ) : null}

            { userDetails && userDetails.role === "Supplier" ? (
              <>
                {/* Supplier dashboard routes start */}
                <NavLink to="/dashboard" className="nav-link">
                  <i className="fa fa-tachometer icon"></i>
                  <span className="title">Dashboard</span>
                </NavLink>
                {/* <NavLink to="/supplier/clients" className="nav-link">
                  <Icon.User className="icon" />
                  <span className="title">My Clients</span>
                </NavLink> */}

                <NavLink to="/supplier/pendingForms" className="nav-link">
                  <Icon.HelpCircle className="icon" />
                  <span className="title">Pending Forms</span>
                </NavLink>
                <NavLink to="/supplier/completedForms" className="nav-link">
                  <Icon.HelpCircle className="icon" />
                  <span className="title">Completed Forms</span>
                </NavLink>

                <NavDropdown
                  title={
                    <div className="dropdown-title">
                      <Icon.TrendingUp className="icon" />
                      <span className="title">
                        Reports
                        <Icon.ChevronRight className="icon fr" />
                      </span>
                    </div>
                  }
                  id="basic-nav-dropdown"
                >
                  <NavLink
                    to="/supplier/reports/currentClients"
                    className="dropdown-item"
                    // className="nav-link"
                  >
                    <i
                      className="fa fa-comments icon-colors"
                      aria-hidden="true"
                    ></i>
                    &nbsp;&nbsp;
                    <span className="title"> Current Clients</span>
                  </NavLink>
                  <NavLink to="/supplier/reports/forms" className="dropdown-item">
                    <i
                      className="fa fa-calendar icon-colors"
                      aria-hidden="true"
                    ></i>
                    &nbsp;&nbsp;
                    <span className="title"> Froms </span>
                  </NavLink>

                  <NavLink
                    to="/supplier/reports/auditStatus"
                    className="dropdown-item"
                  >
                    <i
                      className="fa fa-history icon-colors"
                      aria-hidden="true"
                    ></i>{" "}
                    &nbsp;&nbsp;
                    <span className="title"> Audit Status </span>
                  </NavLink>
                </NavDropdown>
                {/* Supplier dashboard routes end */}
              </>
            ): null}

            { userDetails && userDetails.role === "Admin" ? (
              <>
                {/* Supplier dashboard routes start */}
                <NavLink to="/dashboard" className="nav-link">
                  <i className="fa fa-tachometer icon"></i>
                  <span className="title">Dashboard</span>
                </NavLink>
                <NavLink to="/admin/clients" className="nav-link">
                  <Icon.User className="icon" />
                  <span className="title">Clients</span>
                </NavLink>

                <NavLink to="/admin/suppliers" className="nav-link">
                  <i class="fa fa-handshake-o icon"></i>
                  <span className="title">Suppliers</span>
                </NavLink>
                <NavLink to="/admin/formLibrary" className="nav-link">
                  <Icon.FileText className="icon" />
                  <span className="title">Form Library</span>
                </NavLink>
                <NavLink to="/admin/sentForms" className="nav-link">
                  <i class="fa fa-paper-plane icon"></i>
                  <span className="title">Sent Forms</span>
                </NavLink>

                <NavLink to="/admin/questionLibrary" className="nav-link">
                  <Icon.HelpCircle className="icon" />
                  <span className="title">Question Library</span>
                </NavLink>
                {/* <div className="nav-link" onClick={handleInviteSupplier}>
                  <Icon.UserPlus className="icon" />
                  <span className="title">Invite Suppliers</span>
                </div> 
                <div className="nav-link" onClick={handleInviteClient}>
                  <Icon.UserPlus className="icon" />
                  <span className="title">Invite Client</span>
                </div> */}

                {/* Supplier dashboard routes end */}
              </>
            ): null}


          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {
        userDetails.role === SUPPLIER ? null :(
          <>
            <div className="quick-links">
              Quick Links
            </div>
            <Navbar className={`sidemenu ${props.sideMenu ? "hide-nav-title" : ""}`}>
              <Navbar.Collapse>
                <Nav>
                  <NavLink to="/admin/formLibrary/formBuilder" className="nav-link">
                    <i class="fa fa-plus-circle icon" style={{position:"relative",fontSize:"18px",top:"1px"}}></i>
                    <span className="title">Create Form</span>
                  </NavLink>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </>
        )
      }
    </div>
  );
};

export default SideMenuLight;
