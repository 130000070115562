import React, { useState } from "react";
import { InputGroup } from "react-bootstrap";

const Filters = (props) => {
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState([]);

  const handleChange = async (event) => {
    const value = event.target.value;
    const name = event.target.name;
    if (name === "category") {
      setSelectedCategory([value]);
    }
    if (name === "question") {
      setSelectedQuestion([value]);
    }
  };
  const searchByCategory = () => {
    console.log(selectedCategory);
  };
  const searchByQuestion = () => {
    console.log(selectedQuestion);
  };
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div className="spacer"></div>
        <div>
          <InputGroup>
            <input
              type="text"
              className="form-control"
              name="category"
              placeholder="Search Category"
              onChange={handleChange}
            />
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">
                <span
                  className="fa fa-search"
                  onClick={searchByCategory}
                ></span>
              </InputGroup.Text>
            </InputGroup.Prepend>
          </InputGroup>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <div className="spacer"></div>
        <div>
          <InputGroup>
            <input
              type="text"
              className="form-control"
              name="question"
              placeholder="Search Questions here..."
              onChange={handleChange}
            />
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroupPrepend">
                <span
                  className="fa fa-search"
                  onClick={searchByQuestion}
                ></span>
              </InputGroup.Text>
            </InputGroup.Prepend>
          </InputGroup>
        </div>
      </div>
    </div>
  );
};

export default Filters;
