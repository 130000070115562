import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  Modal,
  lg,
  Alert
} from "react-bootstrap";
import { useDispatch ,useSelector} from "react-redux";
import PageLoader from "../../common/Loader";
import DataTable from "../../components/Tables/DataTable";
const PublishedFormsList = (props) => {
    const { sendFormsAction } = props;
  const dispatch = useDispatch();
  const { publishedForms ,loader } = useSelector((state) => state.supplier);
  const [show, setShow] = useState(props.isOpen);
  const [selectedForms, setSelectedForms] = useState([]);
  const checkBoxAction = (data)=>{
      if(data.id){
        const newData = [...selectedForms];
        const formIndex = selectedForms.indexOf(data.id);
        if( formIndex ===-1){
            newData.push(data.id)
            setSelectedForms(newData)
        }else{
          newData.splice(formIndex,1);
          setSelectedForms(newData)
        }
      }
      
  }
  const headersList = [
      {
          name: "CheckBox Selection",
          key:"id",
          requiredActions: [
            {
              actionKey: "BasicData",
              icon: "",
              handleAction: checkBoxAction,
            },
          ],
          
      },
    {
      name: "Form Name",
      key: "name"
    },
    { name: "Status", key: "status" },
    { name: "Created By", key: "created_by" },
    // { name: "Created On", key: "createdAt" },
  ];
  const handleClose = () => {
    setShow(false);
    props.handleClose();
  };
  const handleSubmit =async()=>{
      await sendFormsAction(selectedForms);
      props.handleClose();
  }

  return (
    <div className="page-wrapper">
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Forms List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
      <PageLoader loader={loader} >
            {publishedForms.length > 0 ? (
              <DataTable header={headersList} data={publishedForms} />
            ): (<Alert variant="success">No published forms to view</Alert>)}
            
            <div className="spacer"></div>
            <div className="row">
                <div className="col-10">
                </div>
                <div className="col-1">
                    <Button className="btn app-default-button pull-right" onClick={handleClose}>Close</Button> &nbsp;&nbsp; &nbsp;
                </div>
                <div className="col-1">
                    <Button className="btn app-default-button pull-right" onClick={handleSubmit}>Submit</Button>
                </div>
            </div>
    </PageLoader>
        
        </Modal.Body>
      </Modal>
    {/* </div> */}
      </div>
      
    // </div>
  // </div> 
    
  );
};

export default PublishedFormsList;
