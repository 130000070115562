import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import Questions from "./Questions";
import Filters from "./filter";
import { Tab, Nav, Button } from "react-bootstrap";
import CreateNewQuestion from "../FormBuilder/createNewQuestion";
import PageLoader from "../../common/Loader";
import {
  createGlobalQuestion,
  getQuestionsList,
  deleteQuestion,
  addQuestionToForm,
  getAdminQuestionsList,
  resetQuestionsList,
  updateQuestionAction,
  deleteFormQuestionLibraryAction,
  checkIsQuestionAdded,
  resetIsQuestionAdded
} from "../../redux/actions/questionLibraryAction";
import { ADMIN, CLIENT,QUESTION_LIBRARY } from "../../common/constants";
import { questionDetailsAction, resetQuestionData } from "../../redux/actions/formLibraryAction";
import Footer from "../Footer";
import { useParams } from "react-router";
import SweetAlert from 'react-bootstrap-sweetalert';

const QuestionLibrary = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const formId = params.id;
  const sectionName = params.section_name;
  const { userDetails } = useSelector((state) => state.user);
  const { addQuestionFromLibrary } = useSelector((state) => state.form);
  const { loader, questionsList , adminQuestionsList,isQuestionAddedToForm } = useSelector((state) => state.question);
  const [sideMenu, setSideMenu] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [globalQuestionsData, setGlobalQuestionsData] = useState([]);
  const [localQuestionsData, setLocalQuestionsData] = useState([]);
  const [formQuestionIds, setFormQuestionIds] = useState({})
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [individualQuestion, setIndivindualQuestion] = useState(null);
  useEffect(() => {
   async function fetchQuestionsDetails(){
     dispatch(resetQuestionsList())
     dispatch(resetIsQuestionAdded())
    if (userDetails && userDetails.role === CLIENT) {
      await fetchQuestionsList({
        client_id:null
      });
    }else{
      await dispatch(getAdminQuestionsList())
    }
   }
    fetchQuestionsDetails();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (userDetails && userDetails.role === CLIENT) {
      const globalQuestionsListData = questionsList.filter(
        (obj) => obj.client_id === userDetails.client_id
      );
      const localQuestionsListData = questionsList.filter(
        (obj) => obj.client_id !== null
      );
      console.log("======",questionsList,globalQuestionsData,localQuestionsData)
      setGlobalQuestionsData(globalQuestionsListData);
      setLocalQuestionsData(localQuestionsListData);
    }else{
      const globalQuestionsListData = adminQuestionsList.filter(
        (obj) => obj.client_id === null
      );
      const localQuestionsListData = adminQuestionsList.filter(
        (obj) => obj.client_id !== null
      );
      setGlobalQuestionsData(globalQuestionsListData);
      setLocalQuestionsData(localQuestionsListData);
    }

  }, [questionsList, adminQuestionsList]);
  const closeModal = (value) => {
    setIsOpen(value);
    setSelectedQuestion(null);
    dispatch(resetQuestionData());
  };
  const onSideMenu = (value) => {
    setSideMenu(value);
  };
  const openModal = () => {
    dispatch(resetIsQuestionAdded())
    setIsOpen(true);
  };

  const addAction = async(data) => {
    const requestedParams = {
      form_questions_ids : [data],
      // ...addQuestionFromLibrary,
      role:userDetails.role,
      form_id: formId,
      section: sectionName
    }
    console.log(data)
    if(formId && sectionName){
      await dispatch(checkIsQuestionAdded({
        form_id: formId,
        // section : sectionName,
        question_id: data,
        requestedParams,
        reqFrom:'AddButton'
      }))
      // if(!isQuestionAddedToForm){
      //   await dispatch(addQuestionToForm(requestedParams));
      // }
    }
    setIndivindualQuestion(requestedParams)
    
  };
  const deleteAction = async(id) => {
    await dispatch(
      deleteQuestion({ id })
    );
    fetchQuestionsList({client_id: userDetails.client_id})
  };
  const updateQuestion = async (selectedOption) => {
    await dispatch(
      updateQuestionAction({
        ...selectedOption,
        id: selectedQuestion,
        // client_id: userDetails.client_id,
        // level: "form",
        // form_id: formData.id,
        // section: sectionQuestion,
      })
    );
    await dispatch(getAdminQuestionsList())
    setIsOpen(false);
  };

  const deleteQuestionFromLibrary = async (value) => {
    await dispatch(
      deleteFormQuestionLibraryAction({
        id: value.id,
      })
    );
    await dispatch(getAdminQuestionsList())
  };
  const editAction = async (value) => {
    // dispatch(resetQuestionData());
    console.log(value)
    setSelectedQuestion(value.id)
    await dispatch(
      questionDetailsAction({
        question_id: value.id
      })
    );
    setIsOpen(true)

  };
  const localQuestionActions  = [
    {
      name: "Edit",
      icon: "fa fa-edit",
      onClick: editAction,
    },
    {
      name: "Delete",
      icon: "fa fa-trash-o",
      onClick: deleteQuestionFromLibrary,
    }
    
  ];
  const  globalQuestionActions = [
    {
      name: "Add",
      icon: "fa fa-plus",
      onClick: addAction,
    },
    // {
    //   name: "Delete",
    //   icon: " fa fa-trash",
    //   onClick: deleteAction,
    // },
  ];
  const create = async (selectedOption) => {
    let requestedParams = { ...selectedOption, client_id: userDetails.client_id };
    if( formId && sectionName){
      requestedParams.form_id = formId
      requestedParams.section = sectionName
    }
    await dispatch(
      createGlobalQuestion(requestedParams)
    );
    // setIsOpen(false);
    fetchQuestionsList({client_id: userDetails.client_id})
    await dispatch(getAdminQuestionsList())
    if(selectedOption.isModalClose){
      setIsOpen(false);
    }

  };
const selectTab =async(value)=>{
  if(value ==='Gloabl'){
    fetchQuestionsList({
      client_id:null
    });
  }else{
    fetchQuestionsList({
      client_id: userDetails.client_id
    });
  }
}
const fetchQuestionsList= async({client_id})=>{
  await dispatch(getQuestionsList({
    client_id
  }));
}
const checkBoxSelectionChange =async(question)=>{
  if(formId && sectionName){
    await dispatch(checkIsQuestionAdded({
      form_id: formId,
      // section : sectionName,
      question_id: question.id
    }))
  }
  if(!question.isChecked){
    const data = globalQuestionsData.filter(obj=>{
      if(obj.id === question.id){
        if(obj.isChecked){
          delete formQuestionIds[`${question.id}`]
          setFormQuestionIds(formQuestionIds);
        }else{
          formQuestionIds[`${question.id}`] = question.id;
          setFormQuestionIds(formQuestionIds);
        }
        obj.isChecked = !obj.isChecked

      }
      return obj;

    })
    console.log(data)
    setGlobalQuestionsData(data);
    setSelectedQuestion(question);
  }else{
    const data = globalQuestionsData.filter(obj=>{
      if(obj.id === question.id){
        if(obj.isChecked){
          delete formQuestionIds[`${question.id}`]
          setFormQuestionIds(formQuestionIds);
        }else{
          formQuestionIds[`${question.id}`] = question.id;
          setFormQuestionIds(formQuestionIds);
        }
        obj.isChecked = !obj.isChecked

      }
      return obj;

    })
    console.log(data)
    setGlobalQuestionsData(data);
  
  }
 
}
const addQuestionsToLibrary =async()=>{
  console.log("Add Question Library ",formQuestionIds)
    const requestedParams = {
      form_questions_ids : Object.keys(formQuestionIds),
      // ...addQuestionFromLibrary,
      role:userDetails.role,
      form_id: formId,
      section: sectionName
    }
    await dispatch(
      addQuestionToForm(requestedParams)
    );
}
const onConfirm =async(value)=>{
  if(selectedQuestion){
    const data = globalQuestionsData.filter(obj=>{
      if(obj.id === selectedQuestion.id){
        if(obj.isChecked){
          formQuestionIds[`${selectedQuestion.id}`] = selectedQuestion.id;
          setFormQuestionIds(formQuestionIds);
          
          // obj.isChecked = !obj.isChecked
        }else{
          delete formQuestionIds[`${selectedQuestion.id}`]
          setFormQuestionIds(formQuestionIds);
          // obj.isChecked = !obj.isChecked
        }
        
  
      }
      return obj;
    })
    setGlobalQuestionsData(data);
    setSelectedQuestion(null);
  }else{
    await dispatch(
      addQuestionToForm(individualQuestion)
    );
    setIndivindualQuestion(null);
  }
  
}
const onCancel =async(value)=>{
  if(selectedQuestion){
    const data = globalQuestionsData.filter(obj=>{
      if(obj.id === selectedQuestion.id){
        if(obj.isChecked){
          delete formQuestionIds[`${selectedQuestion.id}`]
          setFormQuestionIds(formQuestionIds);
        }else{
          formQuestionIds[`${selectedQuestion.id}`] = selectedQuestion.id;
          setFormQuestionIds(formQuestionIds);
        }
        // obj.isChecked = ( selectedQuestion.isChecked || false )
        obj.isChecked = false;
  
      }
      return obj;
    })
    console.log(data)
    setGlobalQuestionsData(data);
    setSelectedQuestion(null);
  }else{
    // await dispatch(
    //   addQuestionToForm(individualQuestion)
    // );
    setIndivindualQuestion(null);
  }
  
}

  return (
    <div className="page-wrapper">
      <PageLoader loader={loader}>
        <Layout onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          {/* <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-5 col-lg-6 col-xl-8 "></div>
            <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-4">
              <Filters />
            </div>
          </div> */}
          {
            (selectedQuestion || individualQuestion ) && isQuestionAddedToForm ? (
              <>
              <SweetAlert
              warning
              showCancel
              cancelBtnText="No"
              confirmBtnText="Yes"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              title="Are you sure?"
              onConfirm={onConfirm}
              onCancel={onCancel}
              focusCancelBtn
            >
              This question has aleady been added to the form. Are you sure you want to add it again?
            </SweetAlert>
              </>
            ):null 
          }
          {/* {Object.keys(addQuestionFromLibrary).length > 0 ? ( */}
          { formId && sectionName ? (
                    <div className="row">
                    <div className="col-8"></div>
                    <div className="col-4 text-right">
                    {
                      userDetails && userDetails.role === ADMIN ? (
                        <Button
                        variant="btn app-default-button btn-sm btn-primary"
                        type="button"
                        onClick={openModal}
                        style={{marginRight: "15px"}}
                      >
                        Create New
                      </Button>
                      ): null 
                    }
                    <Button
                      variant="btn app-default-button btn-sm btn-primary"
                      type="button"
                      onClick={addQuestionsToLibrary}
                    >
                      Bulk Add
                    </Button>
                    </div>
                  </div>
                  ) : (
                    <div className="row">
                      <div className="col-10">

                      </div>
                      <div className="col-2 text-right">
                          <Button className="btn app-default-button" onClick={openModal}> Create New </Button>
                      </div>
                    </div>
                  ) }
                  
          <div className="custom-tabs pill-tab ">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              {/* <Nav variant="pills" className="tabs-style-two">
                <Nav.Item>
                  <Nav.Link eventKey="first" onClick={()=>{selectTab('Gloabl')}}>
                    <b>Global Questions</b>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" onClick={()=>{selectTab('Local')}}>
                    <b>Local Questions</b>
                  </Nav.Link>
                </Nav.Item>
              </Nav> */}

              <Tab.Content>
                <Tab.Pane eventKey="first" >
                  
                  
                  <Questions
                    data={globalQuestionsData}
                    add={addAction}
                    // actions={globalQuestionActions}
                    delete={deleteAction}
                    // actions={Object.keys(addQuestionFromLibrary).length > 0 ? globalQuestionActions : localQuestionActions }
                    actions={ formId && sectionName ? globalQuestionActions : localQuestionActions }
                    checkBoxSelection={checkBoxSelectionChange}
                    // isAddFromLibrary={Object.keys(addQuestionFromLibrary).length > 0}
                    isAddFromLibrary ={ formId && sectionName }

                  />
                  <div className="spacer"></div>
                  <div className="spacer"></div>
                </Tab.Pane>

                <Tab.Pane eventKey="second">
                  {
                    userDetails && userDetails.role === CLIENT ? (
                      <>
                      <Questions
                    data={localQuestionsData}
                    add={addAction}
                    delete={deleteAction}
                    actions={localQuestionActions}
                  />
                      </>
                    ): (
                      <>
                       <Questions
                    data={localQuestionsData}
                    add={addAction}
                    delete={deleteAction}
                    actions={localQuestionActions}
                  />
                      </>
                    )
                  }
                  
                  <div className="spacer"></div>
                  <div className="spacer"></div>
                  {/* <Button
                    variant="btn app-default-button btn-sm btn-primary"
                    type="button"
                    onClick={openModal}
                  >
                    Create New
                  </Button> */}
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
          <CreateNewQuestion
            isOpen={isOpen}
            closeModal={closeModal}
            createQuestion={create}
            selectedQuestion={selectedQuestion}
            updateQuestion={updateQuestion}
            redirectFrom={QUESTION_LIBRARY}
          />
          {/* <Question data={data} questionId={123} /> */}
          <div className="flex-grow-1"></div>
                    <Footer /> 
        </div>
      </PageLoader>
    </div>
  );
};

export default QuestionLibrary;
