import React, { useState, useEffect } from "react";
import { Modal, Button, Form, InputGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useForm,Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ReactSelect from "react-select";
import DataTable from "../../components/Tables/DataTable";
import { ERROR, ToastMessage } from "../../common/ToastMessage";
import PageLoader from "../../common/Loader";
import { CHOOSE_MORE_THAN_ONE, CHOOSE_ONE_ONLY, DATE_TYPE, FORM_BUILDER, LONG_ANSWER, MULTIPLE_CHOICE_WITH, OPTIONS, QUESTION_LIBRARY, QUESTION_NUMBER, SHORT_ANSWER, STATEMENT,YES_OR_NO } from "../../common/constants";
import  { checkIsQuestionAdded, createQuestionTabsList ,resetQuestionTabsList } from "../../redux/actions/questionLibraryAction";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import SweetAlert from 'react-bootstrap-sweetalert';

const CreateNewQuestion = (props) => {
  const dispatch = useDispatch();
  const {  isOpen, closeModal ,createQuestion , selectedQuestion,updateQuestion } = props;
  const { loader , questionTabsList ,isQuestionAddedToForm } = useSelector(state => state.question)
  const {formData ,questionData,question_options_list } = useSelector(state => state.form)
  const [show, setShow] = useState(props.isOpen);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedOptionType, setSelectedOptionType] = useState([]);
  const [questionOptions, setQuestionOptions] = useState(question_options_list);
  const [option, setOption] = useState("");
  const [question, setQuestion] = useState("");
  const [riskScore, setRiskScore] = useState("");
  const [submitButtonName, SetSubmitButtonName] = useState(null);
  const [isOptionsEditable, setIsOptionsEditable] = useState(false);
  const [editOptionData, setEditOptionData] = useState(null);
  const [isQuestionAlreadyAdded, setIsQuestionAlreadyAdded] = useState(true);
  
  const validationSchema = Yup.object().shape({
    question: Yup.string().required("Field is required "),
    question_type: Yup.object().required("Question Type is required").nullable(),
    question_options: Yup.string()
      .ensure()
      .when("question_type", {
        is: OPTIONS,
        then: Yup.string().required("Option is required "),
      }),
      riskScore: Yup.string()
      .ensure()
      .when("question_type", {
        is: OPTIONS,
        then: Yup.string().required("Option is required "),
      }),
      options_type: Yup.object().nullable()
      // .ensure().when("question_type", {
      //   is: "Options",
      //   then: Yup.string().required("Option is required "),
      // }).nullable(),

  });
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    setError,
    reset,
    watch,
    formState: { errors },
    onBlur,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    setShow(props.isOpen);
    dispatch(resetQuestionTabsList())
    if(Object.keys(questionData).length === 0){
      reset();
     setQuestionOptions([])

    }
  }, [props.isOpen]);
  useEffect(() => {
    if(isQuestionAddedToForm){
      setIsQuestionAlreadyAdded(true)
    }else{
      clearErrors(['question'])
    }
  }, [isQuestionAddedToForm]);
  useEffect(() => {
    if(selectedQuestion && Object.keys(questionData).length > 0){
      setQuestionOptions(questionData.questions_options);
      setValue("question",questionData.question)
      setValue("question_type",{
        value: questionData.question_type,
        label: questionData.question_type
      })
      
      if(questionData.question_type === OPTIONS){
        setValue("options_type",{
          value: questionData.options_type,
          label: questionData.options_type
        })
        setSelectedOption([{
          value: questionData.question_type,
          label: questionData.question_type
        }])
        setSelectedOptionType([{
          value: questionData.options_type,
          label: questionData.options_type
        }])
      }
    }else{
      setSelectedOption([]);
      setSelectedOptionType([])
      reset();
    }
  }, [questionData])

  useEffect(() => {
    reset();
  }, [])
  useEffect(() => {
    if(!selectedQuestion){
      setValue("question_type",[])

    }
  }, [questionTabsList])
  const handleClose = () => {
    setShow(false);
    props.closeModal(false);
    setIsQuestionAlreadyAdded(false);
  };
  // const handleShow = () => {
  //   setShow(true);
  // };
  const questionTypeOptions = [
    { value: STATEMENT, label: STATEMENT },
    { value: SHORT_ANSWER, label: SHORT_ANSWER },
    { value: LONG_ANSWER, label: LONG_ANSWER },
    { value: QUESTION_NUMBER, label: QUESTION_NUMBER },
    { value: OPTIONS, label: OPTIONS },
    { value: YES_OR_NO, label: YES_OR_NO },
    { value: DATE_TYPE, label: DATE_TYPE },
  ];
  const questionSubOptions = [
    { value: CHOOSE_ONE_ONLY, label: CHOOSE_ONE_ONLY },
    { value: CHOOSE_MORE_THAN_ONE, label: CHOOSE_MORE_THAN_ONE },
    // { value: "Conditional questions", label: "Conditional questions" },
    { value: MULTIPLE_CHOICE_WITH, label: MULTIPLE_CHOICE_WITH },
  ];
  const handleChange = (data) => {
    console.log(data)
    if(data && data.value){
      setValue("question_type",data);
      if (data.value !== OPTIONS) {
        setQuestionOptions([]);
        setSelectedOptionType([])
      }
      setSelectedOption([data]);
      clearErrors(["question_type"])
    }
   
  };
  const optionTextWatch = watch("question_options");
  const questionName = watch("question");
  useEffect(() => {
    if(questionName){

    }else{
      // setIsQuestionAlreadyAdded(false)
    }
    if(questionName){

    }

  }, [questionName]);

  useEffect(() => {
    if(!isOptionsEditable){
      setValue('riskScore',optionTextWatch)
    }
  }, [optionTextWatch]);

  const handleMultipleQuestions = (data) => {
    if(data && data.value){
      setValue("multipleChoices",data);
      // if (data.value !== "Options") {
      //   setQuestionOptions([]);
      // }
      setSelectedOptionType([data]);
      clearErrors(["multipleChoices"])
    }
  };
  const handleInputChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    if (name === "option") {
      setOption(value);
      setValue("question_options",value)
      setValue("riskScore",value)
    }
    if (name === "riskScore") {
      setRiskScore(value);
    }
    if (name === "question") {
      setQuestion(value);
    }
  };

  const addOptions = () => {
    const questionOptionData = getValues("question_options")
      const riskScoreData = getValues("riskScore");
    const dupArray = [...questionOptions];
    const obj = {
      option_value: questionOptionData,
      option_number: riskScoreData,
      id: dupArray.length,
    };
    const data = dupArray.filter(
      (obj) => obj.option_text === questionOptionData && obj.option_number === riskScoreData
    );
    if (data.length > 0) {
      ToastMessage(ERROR, "Already Existed");
    } else {
      
      if(!questionOptionData && !riskScoreData){
        setError("question_options",{
          type: "focus",
          message: "Question Options is required",
        }, { shouldFocus: true })
        setError("riskScore", {
          type: "manual",
          message: "Option Value is required",
        })
      }else if(!questionOptionData){
        setError("question_options",{
          type: "focus",
          message: "Question Options is required",
        }, { shouldFocus: true })
      }else if(!riskScoreData){
        setError("riskScore", {
          type: "focus",
          message: "Option Value is required",
        }, { shouldFocus: true })
      }else{
        dupArray.push(obj);
        setQuestionOptions(dupArray);
        setValue("question_options",null);
        setValue("riskScore",null);
        clearErrors(["riskScore"])
        clearErrors(["question_options"])
        // setRiskScore("");
      }
      
    }
  };
  const deleteAction = (data) => {
    const updatedData = questionOptions.filter(obj=> obj.id !== data.id);
    setQuestionOptions([...updatedData])
  };
  const editAction = (data) => {
    setIsOptionsEditable(true);
    setEditOptionData(data);
    const updatedData = questionOptions.filter(obj=> obj.id !== data.id);
    setValue("question_options",data.option_value)
    setValue("riskScore",data.option_number)
  };
  const updateOptions = () => {
    const question_options = getValues("question_options")
    const riskScore = getValues("riskScore")
    const updatedData = questionOptions.filter(obj=> {
      if(obj.id === editOptionData.id){
        obj.option_value = question_options;
        obj.option_number = riskScore;
      }
      return obj;
    });
    setQuestionOptions([...updatedData])
    setIsOptionsEditable(false);
    setValue("question_options",null)
    setValue("riskScore",null)
  };
  let  headers = [
    {
      name: "Options Text",
      key: "option_value",
    },
    { name: "Option Value", key: "option_number" },
    {
      name: "Action",
      key: "action",
      requiredActions: [
        {
          actionKey: "Delete",
          icon: "fa fa-trash-o",
          handleAction: deleteAction,
        },
      ],
    },
  ];
  if(selectedQuestion){
      headers = [
      {
        name: "Options Text",
        key: "option_value",
      },
      { name: "Option Value", key: "option_number" },
      {
        name: "Action",
        key: "action",
        requiredActions: [
          {
            actionKey: "Edit",
            icon: "fa fa-pencil",
            handleAction: editAction,
          },
          {
            actionKey: "Delete",
            icon: "fa fa-trash-o",
            handleAction: deleteAction,
          },
         
        ],
      },
    ];
  }
  const onSubmit = async (data) => {
    if (data.question_type.value === OPTIONS && questionOptions.length === 0) {
      ToastMessage(ERROR, "Please add at least 1 option");
    } else {
      if(selectedQuestion){
        props.updateQuestion({
          question_id: selectedQuestion,
          question: data.question,
          question_type: data.question_type.value,
          question_options: questionOptions,
          options_type: selectedOptionType.length > 0 ? selectedOptionType[0].value : null ,
          level: 'form',form_id:formData.id
        });
      }else{
        props.createQuestion({
          question:data.question,
          question_type: selectedOption[0].value,
          options_type: selectedOptionType.length > 0 ? selectedOptionType[0].value : null,
          question_options: questionOptions,
          level: 'form',form_id:formData.id,
          isModalClose: submitButtonName === "Create"
        });
      }
      setQuestionOptions([]);
      setQuestion("");
      setRiskScore("");
      setSelectedOption([]);
      setValue("question_type",[]);
      reset();
      if(submitButtonName === "Add One More"){
         await dispatch(createQuestionTabsList((questionTabsList.length + 1)))
      }
    }
  };
  const onError =(value)=>{
    console.log("Errros ",value)
  }
  const onConfirm =(value)=>{
    setIsQuestionAlreadyAdded(false)
  }
  const onCancel =(value)=>{
    setError("question",{
      type: "focus",
      message: "This question has aleady been added to the form",
    }, { shouldFocus: true })
    setIsQuestionAlreadyAdded(false)
  }
  const onBlurAction=async()=>{
    const questionName = getValues("question")
    console.log("OnBlur Action",{question: questionName})
    if(Object.keys(questionData).length >  0 && questionName === questionData.question){
      
    }else{
      if(props.redirectFrom === FORM_BUILDER){
        await dispatch(checkIsQuestionAdded(({
          question: questionName,
          form_id: formData.id
        })))
      }
    }
    
  }
  return (
    <>
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            {selectedQuestion ? "Update Question" :  "Create New Question" }
          </Modal.Title>
        </Modal.Header>
        <Form
            noValidate
            // validated={validated}
            // onSubmit={e => submitForm(e)}
            onSubmit={handleSubmit(onSubmit,onError)}
          >
        <Modal.Body>
          <PageLoader loader={loader} >
          {
            isQuestionAlreadyAdded && isQuestionAddedToForm ? (
              <>
              <SweetAlert
              warning
              showCancel
              cancelBtnText="No"
              confirmBtnText="Yes"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              title="Are you sure?"
              onConfirm={onConfirm}
              onCancel={onCancel}
              focusCancelBtn
            >
              This question has aleady been added to the form. Are you sure you want to add it again?
            </SweetAlert>
              </>
            ):null 
          }
          {/* <Tabs selectedIndex={(questionTabsList.length-1)}> */}
          <Tabs selectedIndex={0}>
              <TabList>
                {
                  questionTabsList.map(obj=>(
                    <Tab disabled={questionTabsList.length != obj }><h6>Question </h6></Tab>
                  ))
                }
                
              </TabList>
              {
                  questionTabsList.map(obj=>(
                    <TabPanel>
              <Form.Group controlId="formBasicPassword">
              <Form.Label>Question</Form.Label>
              <InputGroup>
                <textarea
                  id="exampleFormControlTextarea1"
                  rows="3"
                  name="question"
                  placeholder="Enter Question Text"
                  // onChange={handleInputChange}
                  // value={question}
                  className={`form-control ${errors.question ? "is-invalid" : ""}`}
                  {...register("question")}
                  onBlur={onBlurAction}
                ></textarea>
                <span className="invalid-feedback">
                  {errors.question?.message}
                </span>
              </InputGroup>
            </Form.Group>
            <div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
                  <Controller
                  name="ReactSelect"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <>
                      <ReactSelect
                        isClearable
                        {...field}
                        options={questionTypeOptions}
                        className={`${errors.question_type ? "is-invalid" : ""}`}
                        isOptionDisabled={(option) => option.disabled}
                        onChange={handleChange}
                        placeholder="Select Question Type"
                      />
                      <span className="invalid-feedback">
                        {errors.question_type?.message}
                      </span>
                    </>
                  )}
                  {...register("question_type")}
                />
                <div className="spacer"></div>
                {selectedOption.length > 0 ? (
                  <>
                  {selectedOption[0].value === OPTIONS ? (
                    <Controller
                  name="ReactSelect"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <>
                      <ReactSelect
                        isClearable
                        {...field}
                        options={questionSubOptions}
                        className={`${errors.options_type ? "is-invalid" : ""}`}
                        isOptionDisabled={(option) => option.disabled}
                        onChange={handleMultipleQuestions}
                        placeholder="Select Multiple-choice questions"
                      />
                      <span className="invalid-feedback">
                        {errors.options_type?.message}
                      </span>
                    </>
                  )}
                  {...register("options_type")}
                />
                  ) : null }
                  </>
                ) : null }
                
                  <div className="spacer"></div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style={{marginTop: "10px"}}>
                      {selectedOption.length > 0 && selectedOption[0].value === OPTIONS && selectedOptionType.length > 0 || (selectedQuestion && questionOptions.length > 0 && questionData.question_type !== YES_OR_NO )  ? (
                        <>
                          {/* {selectedOptionType[0].value === "Options" ? ( */}
                            <>
                              <div className="row">
                                <div className="col-6">
                                  Enter Option Text<br />
                                  <i style={{color:"#5b9259"}}>(This text is displayed to the user)</i>
                                </div>
                                <div className="col-6">
                                  <input
                                    type="text"
                                    name="option"
                                    className={`form-control ${errors.question_options ? "is-invalid" : ""}`}
                                    // onChange={handleInputChange}
                                    // value={option}
                                    {...register("question_options")}
                                  />
                                  <span className="invalid-feedback">
                                    {errors.question_options?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="spacer"></div>
                              <div className="row">
                                <div className="col-6">
                                  Enter Option Value<br />
                                  <i style={{color:"#5b9259"}}>(This value is submitted when the text above is selected)</i>
                                </div>
                                <div className="col-6">
                                  <input
                                    type="text"
                                    name="riskScore"
                                    // onChange={handleInputChange}
                                    // value={riskScore}
                                    className={`form-control ${errors.riskScore ? "is-invalid" : ""}`}
                                    {...register("riskScore")}
                                  />
                                  <span className="invalid-feedback">
                                    {errors.riskScore?.message}
                                  </span>
                                </div>
                              </div>
                              <div className="spacer"></div>
                              <div className="row">
                                <div className="col-6"></div>
                                <div className="col-6 add-button-style" >
                                  {isOptionsEditable ? (
                                    <>
                                      <Button
                                      variant="btn app-default-button btn-sm btn-primary"
                                      type="button"
                                      onClick={updateOptions}
                                    >
                                      Update
                                    </Button> &nbsp;
                                      {/* <Button
                                      variant="btn app-default-button btn-sm btn-primary"
                                      type="button"
                                      onClick={addOptions}
                                    >
                                      Cancel
                                    </Button> */}
                                    </>
                                  ):(
                                    <>
                                    <Button
                                      variant="btn app-default-button btn-sm btn-primary"
                                      type="button"
                                      onClick={addOptions}
                                    >
                                      Add
                                    </Button>
                                    </>
                                  )}
                                  
                                </div>
                              </div>
                            </>
                           {/* ) : null} */}
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="spacer"></div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-7 col-lg-6 col-xl-4">
                  {questionData.question_type !== YES_OR_NO &&  questionOptions.length > 0 ? (
                    <>
                      <DataTable header={headers} data={questionOptions} />{" "}
                    </>
                  ) : null}
                </div>
              </div>
              <div className="spacer"></div>
            </div>
              </TabPanel>
                  ))
                }
              
              
        </Tabs>
            
            </PageLoader>
          
        </Modal.Body>
        <Modal.Footer>
          <div className="row">
            {selectedQuestion ? (
              <div className="col-4"></div>
            ):null }
          <div className={selectedQuestion ? "col-4" : "col-4 add-question-modal-create"  } style={{paddingRight:"0px !important",marginRight:"-13px !important"}}>
          <Button
                  variant="btn app-default-button btn btn-primary pull-right"
                  type="submit" onClick={()=>{
                    SetSubmitButtonName("Create")
                  }}
                >
                  {selectedQuestion ? "Update" :  "Create" }
                </Button>
            </div>
              {selectedQuestion ? null : (
            <div className="col-6" style={{paddingRight:"0px !important"}}>
                <Button
                  variant="btn app-default-button btn btn-primary "
                  type="submit" onClick={()=>{
                    SetSubmitButtonName("Add One More")
                  }}
                >
                  {selectedQuestion ? "Update" :  "Add One More" }
                </Button>
            </div>
              )}
                
          <div className={selectedQuestion ? "col-2 pull-left add-question-modal" : "col-2 pull-left add-question-modal" }>
          <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
          </div>
          
          </div>
          
                        
        </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default CreateNewQuestion;