import React from "react";
import { NavLink ,useHistory} from "react-router-dom";
import "./index.css";
const UserAnalyticsBox = (props) => {
  const { title, content, count, iconUrl, url } = props.data;
  const history = useHistory();
  const redirectToPages =()=>{
    history.push(url)
  }
  return (
    <div className="userAnalyticsBox custom-card"  onClick={redirectToPages} style={{cursor:"pointer"}}>
      <div className="row">
        <div className="col-3 content-title" style={{ paddingTop: "25px" }}>
          <img src={iconUrl} alt="dashboard details" style={{filter:"brightness(0.8)"}}/>
        </div>
        <div className="col-6 content-title">
          <div className="spacer"></div>
          <div className="row">
              <div  >
                
                  {url ? (
                    <>
                      <NavLink to={url} className="dashboard-titles">
                        <span style={{fontSize:"20px !important"}}>{title}</span>
                      </NavLink>
                    </>
                  ) : (
                    <><b>{title}</b></>
                  )}
              </div>
              <div className="content-titles">
                    <span>{content}</span>
              </div>
             
            </div>
        </div>
        <div className="col-3 supplier-numbers">
          <span  style={{fontFamily:`"Roboto" !important`}}>
            <b >
              {count}
            </b>
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserAnalyticsBox;
