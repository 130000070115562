import React, { useState, useEffect,useRef ,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import { Alert, Button, InputGroup, Nav, Tab } from "react-bootstrap";
import FormsList from "./FormsList";
import SendForm from "./sendForm";
import { Link, NavLink } from "react-router-dom";
import addNew from "../../assets/img/Group 171.png";
import { useHistory } from "react-router-dom";
import uploadUrl from './UpstreamFormTemplate.xlsx'
import {
  fetchFormsList,
  sendFormtoSupplierAction,
  updateFormData,
  fetchAdminFormsListAction,
  resetQuestionFormLibraryData,
  formPublishAction,
  getSuppliersListAction,
  ADMIN_FORMS_LIST,
  updateAdminsFormsListAction,
  updateAdminsUnpublishedFormsListAction,
  fetchAdminFormsList,
  uploadFormsAction
} from "../../redux/actions/formLibraryAction";
import { useSelector, useDispatch } from "react-redux";
import { ADMIN, CLIENT, PER_PAGE, PUBLISHED, UN_PUBLISHED } from "../../common/constants";
import PageLoader from "../../common/Loader";
import AgGridTable from "../../components/Tables/agGridTable";
import Footer from '../../pages/Footer'
import { ERROR, ToastMessage } from "../../common/ToastMessage";
const FormLibrary = (props) => {
  // const tableType = "Basic";
  const gridRef = useRef();
  const hiddenFileInput = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [agGridRef, setAgGridRef] = useState(null);
  const [publishAgGridRef, setPublishAgGridRef] = useState(null);
  const [rowNode, setRowNode] = useState(null);
  const { loader, formsList, adminFormsList , suppliersList,pagination,unPublishPagination,adminUnpublishedFormsList } = useSelector(
    (state) => state.form
  );
  const { userDetails } = useSelector((state) => state.user);
  const [sideMenu, setSideMenu] = useState(true);
  const [selectedSearchValue, setSelectedSearchValue] = useState([]);
  const [sendFormOpen, setSendFormOpen] = useState(false);
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [selectedTab, setSelectedTab] = useState(PUBLISHED);
  const [publishData, setPublishData] = useState([]);
  const onSideMenu = (value) => {
    setSideMenu(value);
  };
//   useEffect(() => {
//     setPublishData(adminFormsList)
//     if(publishAgGridRef){
//       publishAgGridRef.current.api.redrawRows();

//     }
//   }, [adminFormsList])
  
  useEffect(async () => {
    if (userDetails && userDetails.role === CLIENT) {
      await dispatch(fetchAdminFormsListAction({
        published: PUBLISHED,
        // page:1,
        // per_page: PER_PAGE
      }));
      // await dispatch(fetchFormsList({
      //   published: PUBLISHED,
      //   page:1,
      //   per_page: PER_PAGE
      // }));
    } else {
      await dispatch(fetchAdminFormsListAction({
        published: PUBLISHED,
        // page:1,
        // per_page: PER_PAGE
      }));
    }
    await dispatch(resetQuestionFormLibraryData())
  }, []);
  const editAction = (data) => {
    
    if (userDetails && userDetails.role === CLIENT) {
      history.push(`/client/formLibrary/formBuilder/${data.id}`);
    } else {
      history.push(`/admin/formLibrary/formBuilder/${data.id}`);
    }
    // setIsOpenBasicData(true);
  };
  const deleteAction = (data) => {
    console.log("asfdasfa", data);
  };
  const sendAction = async(data) => {
    await dispatch(getSuppliersListAction({form_id: data.id}));
    setSendFormOpen(!sendFormOpen);
    setSelectedFormId(data.id);
  };
  const closeModal = (value) => {
    setSendFormOpen(value);
  };
  const sendPublishAction = async(data,removeIndex) => {
    await dispatch(formPublishAction({
      form_id: data.id,
      status: data.status === PUBLISHED ? UN_PUBLISHED : PUBLISHED
    },adminFormsList))
    if(selectedTab === PUBLISHED){
        publishAgGridRef.api.updateRowData({ remove: [data] })
      // publishData.splice(removeIndex,1);
      // gridRef.current.api.refreshInfiniteCache();
    }else{
      agGridRef.api.updateRowData({ remove: [data] })
      //   publishData.splice(removeIndex,1);
      // gridRef.current.api.refreshInfiniteCache();
    }
  };
  const sendUnpublishAction = (data) => {
    setSendFormOpen(!sendFormOpen);
    setSelectedFormId(data.id);
  };
  let redirectUrl = '/client/formLibrary/formBuilder'
  if (userDetails && userDetails.role === ADMIN) {
    redirectUrl = '/admin/formLibrary/formBuilder'
  }
  const globalFormsHeaders = [
    {
      name: "Form Name",
      key: "name",
      requiredActions: [
        {
          actionKey: "BasicData",
          icon: "",
          // handleAction: editAction,
          url: redirectUrl,
        },
      ],
    },
    { name: "Status", key: "status", icon: "fa fa-comment" },
    { name: "Created By", key: "created_by" },
    { name: "Created On", key: "createdAt" },
    {
      name: "Action",
      key: "action",
      requiredActions: [
        {
          actionKey: "edit",
          icon: "fa fa-pencil",
          handleAction: editAction,
        },
        {
          actionKey: "Send",
          icon: "fa fa-paper-plane",
          handleAction: sendAction,
        },
        {
          actionKey: "Publish",
          icon: "fa fa-check-circle",
          handleAction: sendPublishAction,
        },
        // {
        //   actionKey: "Un Publish",
        //   icon: "fa fa-book",
        //   handleAction: sendAction,
        // },
      ],
    },
  ];

  const localFormsHeaders = [
    {
      name: "Form Name",
      key: "name",
      requiredActions: [
        {
          actionKey: "BasicData",
          icon: "",
          handleAction: editAction,
          url: "/client/formLibrary",
        },
      ],
    },
    { name: "Status", key: "status", icon: "fa fa-comment" },
    { name: "Created By", key: "created_by" },
    { name: "Created On", key: "createdAt" },
    {
      name: "Action",
      key: "action",
      requiredActions: [
        {
          actionKey: "edit",
          icon: "fa fa-pencil",
          handleAction: editAction,
        },
        {
          actionKey: "Send",
          icon: "fa fa-paper-plane",
          handleAction: sendAction,
        },
        {
          actionKey: "Delete",
          icon: "fa fa-trash-o",
          handleAction: deleteAction,
        },
      ],
    },
  ];


  const statusRender = props => {
    return (
      <>
        {
          props.data ? (
            <>{
              props.data.status === "Published" ? (
                <>
                  <i class="fa fa-circle" aria-hidden="true" style={{ color: "#318c2d", fontSize: "10px" }}></i> &nbsp; {props.data.status}
                </>
              ) : (
                <>
                <i class="fa fa-circle" aria-hidden="true" style={{color: "rgb(255, 96, 96)", fontSize: "10px" }}></i> &nbsp; {props.data.status}
              </>
              )
            }
            </>
          ):null
        }
          
      </>
    );
  };
const AidRenderComponent = props => {
    return (
      <>  
      {props.data ? (
        <><NavLink to={`${redirectUrl}/${props.data.id}`}  >
        <span style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          color: "black",
          
        }}>{props.value}</span> </NavLink>
        </>
      ) : null }
      </>
      
    );
  };
  const actionsListComponent = props => {
    return (
      <>
        {
          props.data ? (
            <>
            {props.data.status === PUBLISHED ? (
          <>
            <i class="fa fa-paper-plane ag-grid-row-icons" aria-hidden="true" title="Send" onClick={()=>{
          props.sendAction(props.data);
        }}></i> &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; <i class="fa fa-ban ag-grid-row-icons" aria-hidden="true" title="Unpublish"  onClick={()=>{
        //   console.log(props,props.publishAgGridRef) 
        //   const rowNodeObj = props.publishAgGridRef.current.api.getRowNode(String(props.data.id));
        //   console.log(rowNodeObj)
        //    const removeIndex = props.publishAgGridRef.current.props.rowData.map(obj=> obj.id).indexOf(props.data.id)
          const removeIndex = props.node.rowIndex;
           props.sendPublishAction(props.data,removeIndex);

        }} ></i>
          </>
        ):(
          <>
          <i class="fa fa-pencil ag-grid-row-icons" aria-hidden="true" title="Edit"  onClick={()=>{
          props.editAction(props.data);
        }}></i> &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; <i class="fa fa-check-circle ag-grid-row-icons" aria-hidden="true" title="Publish"  onClick={()=>{
        //   const rowNodeObj = props.agGridRef.current.api.getRowNode(String(props.data.id));
        //   console.log(rowNodeObj,props.data)
        //   const removeIndex = props.agGridRef.current.props.rowData.map(obj=> obj.id).indexOf(props.data.id)
            const removeIndex = props.node.rowIndex;
          props.sendPublishAction(props.data,removeIndex);
        }} ></i>
          </>
        )}
            </>
          ) : null 
        }
      </>
    )  
  };
  
  const actionsListPublishComponent = props => {
    return (
      <>
        {
          props.data ? (
            <>
            {props.data.status === PUBLISHED ? (
          <>
            <i class="fa fa-paper-plane ag-grid-row-icons" aria-hidden="true" title="Send" onClick={()=>{
          props.sendAction(props.data);
        }}></i> &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; <i class="fa fa-ban ag-grid-row-icons" aria-hidden="true" title="Unpublish"  onClick={()=>{
        //   console.log(props,props.publishAgGridRef) 
        //   const rowNodeObj = props.publishAgGridRef.current.api.getRowNode(String(props.data.id));
        //   console.log(rowNodeObj)
          //  const removeIndex = props.publishAgGridRef.current.props.rowData.map(obj=> obj.id).indexOf(props.data.id)
          const removeIndex = props.node.rowIndex;
        //    console.log(removeIndex,props.publishAgGridRef.current.props.rowData)
           props.sendPublishAction(props.data,removeIndex);

        }} ></i>
          </>
        ):(
          <>
          <i class="fa fa-pencil ag-grid-row-icons" aria-hidden="true" title="Edit"  onClick={()=>{
          props.editAction(props.data);
        }}></i> &nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp; <i class="fa fa-check-circle ag-grid-row-icons" aria-hidden="true" title="Publish"  onClick={()=>{
          const rowNodeObj = props.agGridRef.current.api.getRowNode(String(props.data.id));
          const removeIndex = props.agGridRef.current.props.rowData.map(obj=> obj.id).indexOf(props.data.id)
          props.sendPublishAction(props.data,removeIndex);
        }} ></i>
          </>
        )}
            </>
          ) : null 
        }
      </>
    )  
  };
  const globalColumnPublishDefs = [
    // {
    //   headerName: "Id",
    //   field: "id",
    //   // hide:true,
    //   filter:true,
    //   sortable:true,
    //   floatingFilter:true,
    //   cellRendererFramework: AidRenderComponent
    // },
    {
      headerName: "Form Name",
      field: "name",
      filter:true,
      sortable:true,
      floatingFilter:true,
      // cellRendererFramework: AidRenderComponent
    },
    { headerName: "Status", field: "status", icon: "fa fa-comment", cellRendererFramework: statusRender ,filter:true,
    sortable:true,floatingFilter:true,},
    { headerName: "Created By", field: "created_by",filter:true,floatingFilter:true,
    sortable:true },
    { headerName: "Created On", field: "created_on" ,filter:true,floatingFilter:true,
    sortable:true},
    {
      headerName: "Action",
      field: "action",
      cellRenderer: "actionsListPublishComponent",
      cellRendererParams: {
        sendAction,
        editAction,
        sendPublishAction,
        agGridRef,
        publishAgGridRef
      },
    },
  ];
  const globalColumnDefs = [
    // {
    //   headerName: "Id",
    //   field: "id",
    //   // hide:true,
    //   filter:true,
    //   sortable:true,
    //   floatingFilter:true,
    //   cellRendererFramework: AidRenderComponent
    // },
    {
      headerName: "Form Name",
      field: "name",
      filter:true,
      sortable:true,
      floatingFilter:true,
      cellRendererFramework: AidRenderComponent
    },
    { headerName: "Status", field: "status", icon: "fa fa-comment", cellRendererFramework: statusRender ,filter:true,floatingFilter:true,
    sortable:true},
    { headerName: "Created By", field: "created_by",filter:true,floatingFilter:true,
    sortable:true },
    { headerName: "Created On", field: "created_on" ,filter:true,floatingFilter:true,
    sortable:true},
    {
      headerName: "Action",
      field: "action",
      cellRenderer: "actionsListComponent",
      cellRendererParams: {
        sendAction,
        editAction,
        sendPublishAction,
        agGridRef,
        publishAgGridRef
      },
    },
  ];

  const localColumnDefs = [
    {
      headerName: "Form Name",
      field: "name",
      filter:true,
      sortable:true,
      floatingFilter:true,cellRendererFramework: AidRenderComponent
    },
    { headerName: "Status", field: "status", icon: "fa fa-comment",filter:true,floatingFilter:true,
    sortable:true ,cellRendererFramework: statusRender 
  },
    { headerName: "Created By", field: "created_by",filter:true,floatingFilter:true,
    sortable:true },
    { headerName: "Created On", field: "created_on" ,filter:true,floatingFilter:true,
    sortable:true},
    {
      headerName: "Action",
      field: "action",
      cellRenderer: "actionsListComponent",
      cellRendererParams: {
        sendAction,
        editAction,
        sendPublishAction,
        agGridRef,
        publishAgGridRef
      },
    },
  ];

  const handleChange = async (event) => {
    const value = event.target.value;
    const name = event.target.name;
    if (name === "formSearch") {
      setSelectedSearchValue([value]);
    }
  };
  const searchByValue = () => {
  };
  const sendSupplierForm = async (data) => {
    const params = {
      form_id: selectedFormId,
      due_date: data.due_date,
      email: data.email,
      additional_message: data.message,
      supplier_ids:data.supplier_ids,
      supplier_email: data.supplier_email
    };
    await dispatch(
      sendFormtoSupplierAction({ ...params })
    );
    closeModal(false);
  };
  const selectTab = async(labelValue)=>{
    setSelectedTab(labelValue)
    if (userDetails && userDetails.role === CLIENT) {
      // await dispatch(fetchFormsList({
      //   published: labelValue
      // }));
      await dispatch(fetchAdminFormsListAction({
        published: labelValue,
      }));
    } else {
      await dispatch(fetchAdminFormsListAction({
        published: labelValue,
        // page:1,
        // per_page: PER_PAGE
      }));
    }
  }
  const setGridRefData =(params)=>{
    if(selectedTab === PUBLISHED){
      setPublishAgGridRef(params)
    }else{
      setAgGridRef(params)

    }
  }
  const fetchParamsList =()=>{
    return {
      published: selectedTab
    }
  }
  const upateParentRowData =async(data)=>{
    if(selectedTab === PUBLISHED){
      dispatch(updateAdminsFormsListAction({
        data:data.data.data,
        total_items: data.data.pagination.total_count,
        page: data.page,
        total_pages:data.data.pagination.total_pages,
      }))
    }else{
      dispatch(updateAdminsUnpublishedFormsListAction({
        data:data.data.data,
        total_items: data.data.pagination.total_count,
        page: data.page,
        total_pages:data.data.pagination.total_pages,
      }))
    }
    
  }
  const datasourceData = {
    getRows: async (params)=> {
      const { startRow, endRow, filterModel, sortModel } = params;
      const filterKeys = Object.keys(filterModel)
      let reqParams = await fetchParamsList();
      reqParams.page = ( endRow / pagination.per_page );
      reqParams.per_page = pagination.per_page;
      if (sortModel.length) {
        const { colId, sort } = sortModel[0]
        reqParams.sort_by= colId;
        reqParams.order_by= sort.toUpperCase();
      }
      //Filtering
      filterKeys.forEach(filter => {
        // url += `${filter}=${filterModel[filter].filter}&`
        reqParams[`${filter}`]= filterModel[filter].filter;
      })
      let rows = [];
      let totalItems = 0;
      const data = await fetchAdminFormsList(reqParams);
          if(data&&data.data.success){
            totalItems = data.data.pagination.total_count;
            rows = data.data.data;
            const newData = publishData.concat(rows);
            setPublishData([...newData]);
            params.successCallback(rows,totalItems)
          }else{
            params.failCallback();
          }
          
    }
        
  };
  const onGridReady = async(params) => {
    params.api.sizeColumnsToFit();
    setPublishData([])

    await params.api.setDatasource(datasourceData);
  };
  const getRowId = useCallback(function (params) {
    return params.data.id;
  }, []);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleUploadFormChange = async(event) => {
    const fileUploaded = event.target.files[0];
    var extension = fileUploaded.name.split(".").pop();
    if (extension !== "xlsx" && extension != "xls") {
      ToastMessage(
        ERROR,
        "File type not supported. Please upload a xlsx file."
      );
      event.target.value = "";
      return;
    }
    const data = new FormData();
    data.append("forms_data", fileUploaded, fileUploaded.name);
    await dispatch(uploadFormsAction(data));
    event.target.value = "";
  };
  const downloadInputTemplate=()=>{
    window.open(uploadUrl, '_blank');
  }
  return (
    <div className="page-wrapper">
      <PageLoader loader={loader}>
      <Layout onClick={onSideMenu} />
      <div
        className={`main-content d-flex flex-column ${
          sideMenu ? "" : "hide-sidemenu"
        }`}
      >
        <div className="main-content-header">
          <BreadCrumb />
        </div>
        <SendForm
          isOpen={sendFormOpen}
          closeModal={closeModal}
          send={sendSupplierForm}
        />
        <div className="page-wrapper">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
              <Link className="custom-label" to="formLibrary/formBuilder">
                <img src={addNew} alt="Create New" title="Create New Form" width="30px" style={{filter:"brightness(0.8)"}}/> &nbsp;&nbsp;
              <b style={{color:"#318c2d"}}>Create Form</b>
              </Link>
              
              <div className="spacer"></div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-1 col-lg-3 col-xl-4"></div>
            <div className="col-xs-12 col-sm-6 col-md-8 col-lg-6 col-xl-5" style={{textAlign:"right"}}>
            
              <div className="upload-buttons" style={{display:"inline-block",paddingRight:"12px"}}>
                <Button className="upstream-buttons" onClick={downloadInputTemplate}> <i class="fa fa-download " aria-hidden="true" title="Download Excel Template" ></i> &nbsp;&nbsp;
                  <b >Download Excel Template</b></Button>
              </div>

              <div style={{display:"inline-block"}}>
                <button
                  type="file"
                  name="file"
                  className="btn btn-primary upstream-buttons"
                  title="Upload Excel Form"
                  onClick={handleClick}
                >
                  <span>
                    <i className="fa fa-upload" />
                    &nbsp; <b>Upload Excel Form</b>
                  </span>
                </button>
                <input
                  type="file"
                  name="file"
                  ref={hiddenFileInput}
                  onChange={handleUploadFormChange}
                  style={{ display: "none" }}
                 />
              </div>
            </div>
          </div>
          <div className="spacer"></div>
          <div className="custom-tabs pill-tab ">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Nav variant="pills" className="tabs-style-two">
                <Nav.Item>
                  <Nav.Link eventKey="first" onClick={()=>{selectTab(PUBLISHED)}}>
                    <b>Published</b>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second" onClick={()=>{selectTab(UN_PUBLISHED)}}>
                    <b>Unpublished</b>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="first" >
                <div>
                        {userDetails && userDetails.role === CLIENT ? (
                          <>
                          {selectedTab === PUBLISHED ? (
                                <>
                                <AgGridTable 
                                columnDefs={globalColumnPublishDefs}
                                rowData ={adminFormsList}
                                frameworkComponents={{
                                  actionsListPublishComponent
                                }}
                                defaultColDef={{
                                  resizable: true,minWidth: 100}}
                                actionsListComponent={actionsListPublishComponent}
                                fetchParamsList={fetchParamsList}
                                handleAgGridRef={setGridRefData}
                                />
                                </>
                              ):null }
                            {/* {formsList.length > 0 ? (
                              <>
                              <FormsList
                                actionsListComponent={actionsListComponent}
                                globalHeader={globalColumnDefs}
                                localHeader={localColumnDefs}
                                data={formsList}
                                actionsListComponent={actionsListComponent}
                                fetchParamsList={fetchParamsList}
                                handleAgGridRef={setGridRefData}
                                actionType={ADMIN_FORMS_LIST}
                                // pagination={pagination}
                                upateParentRowData={upateParentRowData}
                              />
                              </>
                            ) : (
                              <Alert variant="success">No forms to view</Alert>
                            )} */}
                          </>
                        ) : (
                          <>
                            {/* {adminFormsList.length > 0  || publishData.length > 0 ? ( */}
                              <>
                              {selectedTab === PUBLISHED ? (
                                <>
                                <AgGridTable 
                                columnDefs={globalColumnPublishDefs}
                                rowData ={adminFormsList}
                                frameworkComponents={{
                                  actionsListPublishComponent
                                }}
                                defaultColDef={{
                                  resizable: true,minWidth: 100}}
                                actionsListComponent={actionsListPublishComponent}
                                fetchParamsList={fetchParamsList}
                                handleAgGridRef={setGridRefData}
                                />
                                </>
                              ):null }
                              </>

                            {/* ) : (
                              <Alert variant="success">No forms to view</Alert>
                            )} */}
                          </>
                        )}
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                <div>
            {userDetails && userDetails.role === CLIENT ? (
              <>
               {adminUnpublishedFormsList.length > 0 ? (
                  <>
                    {selectedTab === UN_PUBLISHED ? (
                      <>
                      <FormsList
                        globalHeader={globalColumnDefs}
                        localHeader={localColumnDefs}
                        data={adminUnpublishedFormsList}
                        actionsListComponent={actionsListComponent}
                        handleAgGridRef={setGridRefData}
                        
                      />
                      </>
                    ):null}
                  </>
                 ) : (
                  <Alert variant="success">No forms to view</Alert>
                )}
              </>
            ) : (
              <>
                {adminUnpublishedFormsList.length > 0 ? (
                  <>
                    {selectedTab === UN_PUBLISHED ? (
                      <>
                      <FormsList
                        globalHeader={globalColumnDefs}
                        localHeader={localColumnDefs}
                        data={adminUnpublishedFormsList}
                        actionsListComponent={actionsListComponent}
                        handleAgGridRef={setGridRefData}
                        
                      />
                      </>
                    ):null}
                  </>
                 ) : (
                  <Alert variant="success">No forms to view</Alert>
                )}
              </>
            )}
          </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            </div>
          
        </div>
        <div className="flex-grow-1"></div>
                    <Footer /> 
      </div>
      </PageLoader>
    </div>
  );
};

export default FormLibrary;
