import React from "react";
import { Switch, Route ,Redirect  } from "react-router-dom";
import PendingForms from "../pages/PendingForms";
import { ROOT, DASHBOARD,SUPPLIER_CLIENTS ,SUPPLIER_CURRENT_CLIENTS,SUPPLIER_PENDING_FORMS,SUPPLIER_AUDIT_STATUS,SUPPLIER_FORMS ,
  SUPPLIER_REPORTS, SUBMIT_FORM_ANSWERS,SUPPLIER_COMPLETED_FORMS, SUBMIT_FORM_ANSWERS_NEEDS_FOLLOWUP, ADMIN_SUPPLIERS_ANSWERS_RESPONSE ,SUBMIT_FORM_ANSWERS_DRAFT
     } from "./constants";
import Dashboard from "../components/Dashboard";
import '../assets/css/style.css';
import '../assets/css/responsive.css';
import SupplierCurrentClients from "../pages/Reports/supplierCurrentClients";
import SupplierForms from "../pages/Reports/supplierForms";
import SupplierAuditStatus from "../pages/Reports/supplierAuditStatus";
import Clients from "../pages/Clients";
import SubmitAnswers from "../pages/Suppliers/submitAnswers";
import CompletedForms from "../pages/CompletedForms";
import NeedsFollowUp from "../pages/Suppliers/NeedsFollowUp";
import AdminFormResponse from "../pages/Suppliers/adminFormResponse";
import Draft from "../pages/Suppliers/Draft";

export const SupplierRouterConfig = () => {
  return (
    <div>
      <Switch>
        {/* List all public routes here */}
        <Route exact path={ROOT} >
             <Redirect to={DASHBOARD} />
        </Route>
        <Route exact path={DASHBOARD} component={Dashboard} />
        {/* Supplier routes start */}
        <Route exact path={SUPPLIER_CLIENTS} component={Clients} />
        <Route exact path={SUPPLIER_REPORTS} component={SupplierCurrentClients} />
        <Route exact path={SUPPLIER_PENDING_FORMS} component={PendingForms} />
        <Route exact path={SUPPLIER_COMPLETED_FORMS} component={CompletedForms} />
        <Route exact path={SUPPLIER_CURRENT_CLIENTS} component={SupplierCurrentClients} />
        <Route exact path={SUPPLIER_AUDIT_STATUS} component={SupplierAuditStatus} />
        <Route exact path={SUPPLIER_FORMS} component={SupplierForms} />
        <Route exact path={SUBMIT_FORM_ANSWERS} component={SubmitAnswers} />
        <Route exact path={SUBMIT_FORM_ANSWERS_NEEDS_FOLLOWUP} component={NeedsFollowUp} />
        <Route exact path={ADMIN_SUPPLIERS_ANSWERS_RESPONSE} component={AdminFormResponse} />
        <Route exact path={SUBMIT_FORM_ANSWERS_DRAFT} component={Draft} />

        {/* Supplier routes end */}
        <Route exact path="*">
          <Redirect to={DASHBOARD} />
        </Route>
      </Switch>
    </div>
  );
};
