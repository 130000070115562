import React, { useState } from "react";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import PageLoader from "../../common/Loader";
import DataTable from "../../components/Tables/DataTable";

const ReportForms = (props) => {
  const [sideMenu, setSideMenu] = useState(true);
  const onSideMenu = (value) => {
    setSideMenu(value);
  };
  const headerList = [
    { name: "Form Name", key: "formName" },
    {
      name: "Form Type",
      key: "formType",
    },
    { name: "Total Vendors", key: "totalVendors" },
    { name: "Percentage Of Completion", key: "percentageOfCompletion" },
    { name: "Percentage Of Progess", key: "percentageOfProgress" },
    { name: "Risk Rating", key: "riskRating" },
  ];
  const data = [
    {
      formName: "Form 5",
      formType: "Global",
      totalVendors: "10",
      percentageOfCompletion: "55%",
      percentageOfProgress: "55%",
      riskRating: "Low",
    },
    {
      formName: "Form 5",
      formType: "Global",
      totalVendors: "10",
      percentageOfCompletion: "55%",
      percentageOfProgress: "55%",
      riskRating: "Medium",
    },
    {
      formName: "Form 5",
      formType: "Global",
      totalVendors: "10",
      percentageOfCompletion: "55%",
      percentageOfProgress: "55%",
      riskRating: "Low",
    },
    {
      formName: "Form 5",
      formType: "Global",
      totalVendors: "10",
      percentageOfCompletion: "55%",
      percentageOfProgress: "55%",
      riskRating: "High",
    },
  ];
  return (
    <div className="page-wrapper">
      <PageLoader loader={false}>
        <Layout onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          <DataTable header={headerList} data={data} />
        </div>
      </PageLoader>
    </div>
  );
};

export default ReportForms;
