import React, { useState } from "react";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import PageLoader from "../../common/Loader";
import DataTable from "../../components/Tables/DataTable";

const RiskScore = (props) => {
  const [sideMenu, setSideMenu] = useState(true);
  const onSideMenu = (value) => {
    setSideMenu(value);
  };
  const headerList = [
    {
      name: "Vendor Name",
      key: "name",
    },
    { name: "Revenue", key: "revenue" },
    { name: "Form Name", key: "formName" },
    { name: "Risk Rating", key: "riskRating" },
    { name: "Percentage Of Completion", key: "percentageOfCompletion" },
    { name: "Due Date", key: "dueDate" },
    { name: "Audit", key: "audit" ,requiredActions: [
      {
        name:"Due Date",
        dataKey: "dueDate",
      },{
        name :"Status",
        dataKey:"audit"
      }
    ]},
  ];
  const data = [
    {
      name: "Vendor x",
      revenue: "$ 15,0000",
      formName: "Form 5",
      riskRating: "High",
      percentageOfCompletion: "55%",
      dueDate: "21/07/24",
      audit: "Completed",
    },
    {
      name: "Vendor x",
      revenue: "$ 15,0000",
      formName: "Form 5",
      riskRating: "Medium",
      percentageOfCompletion: "55%",
      dueDate: "21/07/24",
      audit: "Completed",
    },
    {
      name: "Vendor x",
      revenue: "$ 15,0000",
      formName: "Form 5",
      riskRating:"Low",
      percentageOfCompletion: "55%",
      dueDate: "21/07/24",
      audit: "Not Completed",
    },
    {
      name: "Vendor x",
      revenue: "$ 15,0000",
      formName: "Form 5",
      riskRating:"High",
      percentageOfCompletion: "55%",
      dueDate: "21/07/24",
      audit: "Completed",
    },
  ];
  const nestedHeaders =  ['','','','','','','Due Date','Status']
  return (
    <div className="page-wrapper">
      <PageLoader loader={false}>
        <Layout onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          <DataTable header={headerList} data={data} nestedHeader={nestedHeaders}/>
        </div>
      </PageLoader>
    </div>
  );
};

export default RiskScore;
