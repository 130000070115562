import React ,{ useState } from "react";

import { Alert, Button } from "react-bootstrap";
import { Link,NavLink } from "react-router-dom";
import AgGridTable from "../../components/Tables/agGridTable";
import PublishedFormsList from "./publishedForms";
import { useLocation, useParams } from 'react-router';
import { useDispatch ,useSelector} from "react-redux";
import { getPublishedFormsAction ,sendFormToSupplierAction ,deleteFormsFromSuppliers} from "../../redux/actions/suppliersAction";

const Forms = (props) => {
  const { data,userInfo } = props;
  const params = useParams();
  const dispatch = useDispatch();
  const { isFormEnable } = props;
  const [show, setShow] = useState(false);
  const [gridRef, setGridRef] = useState(null)
  const deleteAction = async(data) => {
    await dispatch(deleteFormsFromSuppliers({ id: data.id,form_id: data.form_id , supplier_id:data.vendor_id }));
    gridRef.api.updateRowData({ remove: [data] })
  };
  const editAction = (data) => {
  };

  const AidRenderComponent = props => {
    return (
      <NavLink to={`/admin/suppliers/${props.data.vendor_id}/submitAnswers/${props.data.form_id}`}  >
        <span style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          color: "black",
          cursor:"pointer"
          
        }}>{props.value}</span> </NavLink>
      
    );
  };
  const deleteRow = props => {
    return (
      <i
        class="fa fa-trash text-center ag-grid-row-icons"
        aria-hidden="true"
        title="Delete"
        onClick={() =>{
          // props.delete(props.data, props.agGridReact.props.rowData)
          const removeIndex = props.node.rowIndex;
          props.delete(props.data,removeIndex)
          // props.delete(props.data)
        }
        }
      ></i>
    );
  };
  const headerList = [
    {
      headerName: "Form Name",
      field: "form_name",
      filter:true,
      sortable:true,floatingFilter:true,
      cellRendererFramework: AidRenderComponent,
      requiredActions: [
        {
          actionKey: "BasicData",
          icon: "",
          handleAction: editAction,
          url: "/admin/suppliers/%{vendor_id}%/submitAnswers",
          navigationId:"form_id"
        },
      ],
    },
    // { name: "Risk Score", key: "riskScore" },
    { headerName: "Status", field: "status", filter:true,floatingFilter:true,
    sortable:true},
    {
      headerName: "Action",
      field: "action",
      cellRenderer: "deleteRow",
      cellRendererParams: {
        delete: deleteAction
      },
      
    },
  ];
  
const sendNewFormHandle = async()=>{
  await dispatch(getPublishedFormsAction({supplier_id: params.id}))
  setShow(true);
}
const handleClose =()=>{
  setShow(false);
}
const sendFormsAction  = async (data)=>{
  await dispatch(sendFormToSupplierAction({
    supplier_id: params.id,
    form_ids: data
  }))
}
  return (
    <div className="page-wrapper">
      <div className="spacer"></div>
      {isFormEnable ? null : (
        <>
          <div className="row">
            <div className="col-10">
              <p className="label-color">
                <span className="fa fa-file-text" style={{fontSize: "15px"}}></span> <b style={{marginLeft: "3px", fontSize: "15px"}}>Supplier Forms</b>
              </p>
            </div>
            <div className="col-2 text-right">
              <Button variant="btn app-default-button btn btn-primary" type="button" onClick={sendNewFormHandle}>
                Send New Form
              </Button>
            </div>
          </div>
          {
            data.length > 0 ? (
              <AgGridTable columnDefs={headerList} rowData={data} frameworkComponents={{
                deleteRow
              }} handleAgGridRef={setGridRef}/>
            ) : (
              <Alert variant="success">No forms to view</Alert>
            )
          }
          
        </>
      )}
      {show ? (
        <>
        <PublishedFormsList  isOpen={show} handleClose={handleClose} sendFormsAction={sendFormsAction}/>
        </>
      ):null}
      <div className="spacer"></div>
    </div>
  );
};

export default Forms;
