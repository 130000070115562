  import { CLOSE_LOADER, OPEN_LOADER } from "../actions/appActions";
import { GET_ADMIN_QUESTIONS_LIST, GET_QUESTIONS_LIST, UPDATE_QUESTION,RESET_ACTIVE_SECTION_NAME,ACTIVE_SECTION_NAME, QUESTION_TABS_LIST,RESET_QUESTION_TABS_LIST, IS_QUESTION_ADDED_TO_FORM } from "../actions/questionLibraryAction";
  
  const initialState = {
    loader: false,
    questionsList: [],
    adminQuestionsList:[],
    activeSectionName: [],
    questionTabsList:[1],
    isQuestionAddedToForm: false
  };
  
  export const questionLibraryReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_QUESTIONS_LIST:
      return {
        ...state,
        questionsList: action.payload,
      };
      case GET_ADMIN_QUESTIONS_LIST:
      return {
        ...state,
        adminQuestionsList: action.payload,
      };
      case OPEN_LOADER:
        return {
          ...state,
          loader: action.payload,
        };
      case CLOSE_LOADER:
        return {
          ...state,
          loader: action.payload,
        };
      case UPDATE_QUESTION:
        const updateQuestion = state.adminQuestionsList.filter(obj=> {
          if( obj.id === action.payload.id){
            obj = { ... action.payload}
          }
          return obj;
        })
          return {
            ...state,
            adminQuestionsList: updateQuestion,
        };
        case ACTIVE_SECTION_NAME:
            return {
              ...state,
              activeSectionName: action.payload,
          };
          case RESET_ACTIVE_SECTION_NAME:
            return {
              ...state,
              activeSectionName: [],
          };
          case QUESTION_TABS_LIST:
            return {
              ...state,
              // questionTabsList: state.questionTabsList.concat(action.payload),
              questionTabsList: [action.payload],
          };
          case RESET_QUESTION_TABS_LIST:
            return {
              ...state,
              questionTabsList: action.payload,
          };
          case IS_QUESTION_ADDED_TO_FORM:
            return {
              ...state,
              isQuestionAddedToForm: action.payload,
          };
          
      default:
        return state;
    }
  };
  