import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import DataTable from "../../components/Tables/DataTable";
const Forms = (props) => {
  const { isFormEnable } = props;
  const deleteAction = (data) => {
    console.log("Deleted Calling");
  };

  const headerList = [
    {
      name: "Form Name",
      key: "name",
    },
    { name: "Option Value", key: "riskScore" },
    { name: "status", key: "status" },
    {
      name: "Action",
      key: "action",
      requiredActions: [
        {
          actionKey: "Delete",
          icon: "fa fa-trash-o",
          handleAction: deleteAction,
        },
      ],
    },
  ];
  const data = [
    {
      name: "From 1",
      riskScore: "7.85",
      status: "Awaiting for Approval",
    },
    {
      name: "Form 12A",
      riskScore: "7.85",
      status: "Approved",
    },
    {
      name: "Form 8B",
      riskScore: "7.85",
      status: "Awaiting for Approval",
    },
    {
      name: "Form 8B",
      riskScore: "7.85",
      status: "Awaiting for Approval",
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="spacer"></div>

      {isFormEnable ? null : (
        <>
          <p className="label-color">
            <span className="fa fa-book"></span> <b>Forms</b>
          </p>
          <DataTable header={headerList} data={data} />
        </>
      )}
      <div className="spacer"></div>
      <Button variant="btn app-default-button btn btn-primary" type="button">
        <Link to="/client/formLibrary"  className="app-default-button btn-sm btn-primary">Send New Form</Link>
      </Button>
      <div className="spacer"></div>
    </div>
  );
};

export default Forms;
