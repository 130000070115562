// You can use CONSTANTS.js file for below definitions of constants and import here.
import callApi from "../../Api";
import moment from 'moment'
import { ToastMessage, SUCCESS, ERROR } from "../../common/ToastMessage";
import { SUPPLIER_PENDING_FORMS } from "../../routes/constants";
import { push } from "react-router-redux";
import { CLIENT, PUBLISHED } from "../../common/constants";
export const OPEN_LOADER = "OPEN_LOADER";
export const CLOSE_LOADER = "CLOSE_LOADER";

export const CREATE_FORM = "CREATE_FORM";
export const FORM_LEVEL_QUESTION_DATA = "FORM_LEVEL_QUESTION_DATA";
export const FORMS_LIST = "FORMS_LIST";
export const FORM_DETAILS = "FORM_DETAILS";
export const UPDATE_FORM_DATA = "UPDATE_FORM_DATA";
export const RESET_FORM_DATA = "RESET_FORM_DATA";
export const ADD_QUESTION_FROM_LIBRARY = "ADD_QUESTION_FROM_LIBRARY"
export const RESET_QUESTION_FORM_LIBRARY = "RESET_QUESTION_FORM_LIBRARY"
export const RESET_FORM_LEVEL_QUESTION_DATA = "RESET_FORM_LEVEL_QUESTION_DATA"
export const SECTIONS_LIST = "SECTIONS_LIST"
export const SET_IS_PUBLISHED = "SET_IS_PUBLISHED"
// ADMIN 
export const ADMIN_FORMS_LIST = "ADMIN_FORMS_LIST";
export const ADD_CUSTOM_SECTION_TO_FORM = "ADD_CUSTOM_SECTION_TO_FORM"
export const GET_SELECTED_SECTIONS_LIST = "GET_SELECTED_SECTIONS_LIST"
export const CREATE_SECTION = "CREATE_SECTION"
export const QUESTION_DATA = "QUESTION_DATA"
export const SUBMIT_ANSWERS_VALIDATION = "SUBMIT_ANSWERS_VALIDATION"
export const GET_SUPPLIERS_LIST ="GET_SUPPLIERS_LIST"
export const RESET_PAGINATION = "RESET_PAGINATION"
export const ADMIN_UNPUBLISHED_FORMS_LIST = "ADMIN_UNPUBLISHED_FORMS_LIST"
export const QUESTION_RE_ORDER = "QUESTION_RE_ORDER"
export const openLoader = (data) => ({
  type: OPEN_LOADER,
  payload: true,
});
export const closeLoader = (data) => ({
  type: CLOSE_LOADER,
  payload: false,
});

export const createForm = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.post(`forms`, reqObj);
  if (data && data.data.success) {
    dispatch({ type: CREATE_FORM, payload: data.data.data });
    if(reqObj.role === CLIENT){
      dispatch(push(`/client/formLibrary/formBuilder/${data.data.data.id}`))

    }else{
      dispatch(push(`/admin/formLibrary/formBuilder/${data.data.data.id}`))

    }
    if(reqObj.id){
      ToastMessage(SUCCESS, "Form Updated successfully.");
    }else{
      ToastMessage(SUCCESS, "Form created successfully.");
    }
    
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};

export const createQuestion = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.post(
    `forms/${reqObj.form_id}/add_question`,
    reqObj
  );
  if (data && data.data.success) {
    const newData = formatMultipleMoreThanOneDropdwon(data.data.data)
    dispatch({ type: FORM_LEVEL_QUESTION_DATA, payload: { sectionsList: newData, editable_sections: [],is_published:false } });
    ToastMessage(SUCCESS, "Question created successfully.");
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};
export const fetchFormsList = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.get(`forms`,{params:reqObj});
  if (data && data.data.success) {
    dispatch({ type: FORMS_LIST, payload: {
      data : data.data.data,
      total_items: data.data.pagination.total_count,
      page: reqObj.page,
      total_pages:data.data.pagination.total_pages,
      page: reqObj.page
    } });
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};

export const sendFormtoSupplierAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.post(
    `forms/${reqObj.form_id}/send_form_to_suppliers`,
    reqObj
  );
  if (data && data.data.success) {
    // dispatch({ type: FORMS_LIST, payload: data.data.data });
    ToastMessage(SUCCESS, "Form successfully send to supplier");
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    ToastMessage(ERROR,data.data.messages.errors[0]);
  }
};
export const formatMultipleMoreThanOneDropdwon = (data)=>{
   Object.keys(data).filter(keyObj=>{
    data[`${keyObj}`].filter(obj=>{
      if(obj.questions_options.length >0 ){
        obj.questions_options = obj.questions_options.filter(childObj=> {
          childObj.value = childObj.option_number;
          childObj.label = childObj.option_value;
          return childObj;
        })
      }
      return obj;
    })
  })
  return data;
}
export const fetchFormData = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.get(
    `forms/${reqObj.form_id}/form_questions_list`,
    reqObj
  );
  if (data && data.data.success) {
    const newData = formatMultipleMoreThanOneDropdwon(data.data.data);
    dispatch({ type: FORM_LEVEL_QUESTION_DATA, payload: { sectionsList: newData, editable_sections: data.data.meta.editable_sections,is_published: data.data.meta.is_published } });
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};

export const updateFormData = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.get(`forms/${reqObj.id}`, reqObj);
  if (data && data.data.success) {
    dispatch({ type: UPDATE_FORM_DATA, payload: data.data.data });
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};

export const resetFormData = (value) => async (dispatch) => {
  dispatch({ type: RESET_FORM_DATA, payload: {} });
};

export const addQuestionFromLibary = (payload) => async (dispatch) => {
  dispatch({ type: ADD_QUESTION_FROM_LIBRARY, payload: payload });
};

export const fetchAdminFormsList = async(reqObj)=>{
  const data = await callApi.get(`forms`,{params:reqObj});
  return data;
}
export const updateAdminsFormsListAction = (data) => ({
  type: ADMIN_FORMS_LIST,
  payload: data,
});
export const updateAdminsUnpublishedFormsListAction = (data) => ({
  type: ADMIN_UNPUBLISHED_FORMS_LIST,
  payload: data,
});
export const fetchAdminFormsListAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await fetchAdminFormsList(reqObj);
  if (data && data.data.success) {
      const updatedData = data.data.data.filter(obj=>{
      obj.createdAt = moment(obj.created_on).format("YYYY-MM-DD HH:MM:SS")
      obj.isPublish = obj.status === PUBLISHED
      obj.isNavigationRequired = obj.status === PUBLISHED
      return obj;
    })
    if(reqObj.published === PUBLISHED){
      dispatch({ type: ADMIN_FORMS_LIST, payload: {
        data : [...updatedData],
        total_items: data.data.pagination.total_count,
        page: reqObj.page,
        total_pages:data.data.pagination.total_pages,
        per_page: reqObj.per_page
      } });
    }else{
      dispatch({ type: ADMIN_UNPUBLISHED_FORMS_LIST, payload: {
        data : [...updatedData],
        total_items: data.data.pagination.total_count,
        page: reqObj.page,
        total_pages:data.data.pagination.total_pages,
        per_page: reqObj.per_page
      } });
    }
    
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};

export const deleteFormQuestion = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.post(`forms/${reqObj.form_id}/delete_question`, reqObj);
  if (data && data.data.success) {
    const newData = formatMultipleMoreThanOneDropdwon(data.data.data);
    // dispatch({ type: FORM_LEVEL_QUESTION_DATA, payload: data.data.data });
    dispatch({ type: FORM_LEVEL_QUESTION_DATA, payload: { sectionsList: newData, editable_sections: [],is_published:false } });
    ToastMessage(SUCCESS, "Form Question deleted successfully");
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};


export const resetQuestionFormLibraryData = (value) => async (dispatch) => {
  dispatch({ type: RESET_QUESTION_FORM_LIBRARY, payload: {} });
};
export const resetFormLevelQuestionData = (value) => async (dispatch) => {
  dispatch({ type: RESET_FORM_LEVEL_QUESTION_DATA, payload: {} });
};

export const createSection = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.post(`sections`, reqObj);
  if (data && data.data.success) {
    const sectionName = {}
    const editable_sections = []
    data.data.data.forEach(obj => {
      sectionName[`${obj.name}`] = [];
      editable_sections.push(obj.name);
    })
    // sectionName[`${data.data.data.name}`] = [];
    dispatch({ type: CREATE_SECTION, payload: {sectionName,editable_sections} });
    ToastMessage(SUCCESS, "Section created successfully.");
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    ToastMessage(ERROR,data.data.messages.errors[0]);
  }
};

export const fetchSectionsList = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.get(`sections`);
  if (data && data.data.success) {
    dispatch({ type: SECTIONS_LIST, payload: data.data.data });
    // ToastMessage(SUCCESS, "Form created successfully.");
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};

export const addCustomSection = (reqObj) => async (dispatch) => {
    dispatch({ type: ADD_CUSTOM_SECTION_TO_FORM, payload: reqObj });
  
};

export const setSelectedSectionAction = (reqObj) => async (dispatch) => {
  dispatch({ type: GET_SELECTED_SECTIONS_LIST, payload: reqObj });

};

export const questionDetailsAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.get(`questions/${reqObj.question_id}`);
  if (data && data.data.success) {
    dispatch({ type: QUESTION_DATA, payload: data.data.data });
    // ToastMessage(SUCCESS, data.data.messages.success[0]);
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};
export const resetQuestionData = (reqObj) => async (dispatch) => {
    dispatch({ type: QUESTION_DATA, payload: {}});
};
export const updateQuestionAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.post(
    `forms/${reqObj.form_id}/edit_question`,
    // `questions/${reqObj.question_id}`,
    reqObj
  );
  if (data && data.data.success) {
    const newData = formatMultipleMoreThanOneDropdwon(data.data.data);
    // dispatch({ type: FORM_LEVEL_QUESTION_DATA, payload: data.data.data });
    dispatch({ type: FORM_LEVEL_QUESTION_DATA, payload: { sectionsList: newData, editable_sections: [],is_published:false } });
    ToastMessage(SUCCESS, "Question updated successfully.");
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};

export const addSectionToFormAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.post(`forms/${reqObj.form_id}/add_section_to_form`,reqObj);
  if (data && data.data.success) {
    // dispatch({ type: QUESTION_DATA, payload: data.data.data });
    const newData = formatMultipleMoreThanOneDropdwon(data.data.data);
    dispatch({ type: FORM_LEVEL_QUESTION_DATA, payload: { sectionsList:newData, editable_sections: data.data.meta.editable_sections,is_published:false } });
    ToastMessage(SUCCESS, data.data.messages.success[0]);
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};

export const reorderSectionsAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.post(`forms/${reqObj.form_id}/reorder_sections`,reqObj);
  if (data && data.data.success) {
    // dispatch({ type: QUESTION_DATA, payload: data.data.data });
    ToastMessage(SUCCESS, data.data.messages.success[0]);
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};

export const reOrderQuestionsAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.post(`forms/${reqObj.form_id}/reorder_questions`,reqObj);
  if (data && data.data.success) {
    // dispatch({ type: QUESTION_DATA, payload: data.data.data });
    const newData = formatMultipleMoreThanOneDropdwon(data.data.data);
    dispatch({ type: FORM_LEVEL_QUESTION_DATA, payload: { sectionsList:newData, editable_sections: data.data.meta.editable_sections,is_published:false } });
    ToastMessage(SUCCESS, data.data.messages.success[0]);
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};

export const addCustomValidationAction = (reqObj) => async (dispatch) => {
  dispatch({ type: SUBMIT_ANSWERS_VALIDATION, payload: reqObj });

};

export const submitAnswersAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.post(`suppliers/save_supplier_response`,reqObj);
  if (data && data.data.success) {
    // dispatch({ type: QUESTION_DATA, payload: data.data.data });
    ToastMessage(SUCCESS, data.data.messages.success[0]);
    dispatch(closeLoader());
    dispatch(push('/supplier/pendingForms'))
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};

export const getSupplierFormResponseAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.get(`suppliers/send_supplier_form_response`, {params: reqObj});
  if (data && data.data.success) {
    const questionsList = Object.keys(data.data.data).filter(keyObj=>{
      data.data.data[`${keyObj}`].filter(obj=>{
        if(obj.questions_options.length >0 ){
          obj.questions_options = obj.questions_options.filter(childObj=> {
            childObj.value = childObj.option_number;
            childObj.label = childObj.option_value;
            return childObj;
          })
        }
        return obj;
      })
    })
    // dispatch({ type: FORM_LEVEL_QUESTION_DATA, payload: data.data.data });
    dispatch({ type: FORM_LEVEL_QUESTION_DATA, payload: { sectionsList: data.data.data, editable_sections: [],is_published:false,
      formStatus: data.data.meta.form_status,
      status_update_reasonses: data.data.meta.status_update_reasonses,
      // formStatusDate: (action.payload.date || null),
      supplier_name: (data.data.meta.supplier_name || null)
    } });
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};


export const formPublishAction = (reqObj, formListData) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.post(`forms/${reqObj.form_id}/publish_form`,reqObj);
  if (data && data.data.success) {
    // const newData = formListData.filter((obj,index)=>{
    //   console.log(obj.id, reqObj.form_id)
    //   if( obj.id === reqObj.form_id ){
    //     obj.status = reqObj.status
    //     obj.isPublish = reqObj.status === PUBLISHED 
    //     obj.isNavigationRequired = reqObj.status === PUBLISHED 
    //   }
    //   return obj;
    // })
    // console.log(newData)
    // dispatch({ type: FORMS_LIST, payload: {
    //   data : newData,
    //   total_items: data.data.pagination.total_count,
    //   page: reqObj.page,
    //   total_pages:data.data.pagination.total_pages,
    //   page: reqObj.page
    // } });
     dispatch({ type: SET_IS_PUBLISHED, payload: reqObj.status === PUBLISHED });
    dispatch(closeLoader());
    ToastMessage(SUCCESS, data.data.messages.success[0]);
  } else {
    dispatch(closeLoader());
    ToastMessage(ERROR,data.data.messages.errors[0]);
  }
};

export const getSuppliersListAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.get(`suppliers/get_suppliers_list`, {params: reqObj});
  if (data && data.data.success) {
    const newData = data.data.data.map(obj=>{
      return {
        value: obj.id,
        label: obj.name
      }
    });
    dispatch({ type: GET_SUPPLIERS_LIST, payload: newData });
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};

export const updateSection = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.post(`forms/${reqObj.id}/update_section`, reqObj);
  if (data && data.data.success) {
    const newData = formatMultipleMoreThanOneDropdwon(data.data.data);
    dispatch({ type: FORM_LEVEL_QUESTION_DATA, payload: { sectionsList:newData, editable_sections: data.data.meta.editable_sections,is_published:false } });
    ToastMessage(SUCCESS, "Section Updated successfully.");
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    ToastMessage(ERROR,data.data.messages.errors[0]);
  }
};

export const deleteSection = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.delete(`forms/${reqObj.id}/remove_section`, {params:reqObj});
  if (data && data.data.success) {
    const newData = formatMultipleMoreThanOneDropdwon(data.data.data);
    dispatch({ type: FORM_LEVEL_QUESTION_DATA, payload: { sectionsList:newData, editable_sections: data.data.meta.editable_sections,is_published:false } });
    ToastMessage(SUCCESS, "Section Deleted successfully.");
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    ToastMessage(ERROR,data.data.messages.errors[0]);
  }
};

export const uploadFormsAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.post(`forms/bulk_forms_creation`, reqObj);
  if (data && data.data.success) {
    ToastMessage(SUCCESS, data.data.messages.success[0]);
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    ToastMessage(ERROR,data.data.messages.errors[0]);
  }
};
export const questionReOrderAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  dispatch({ type: QUESTION_RE_ORDER, payload: { sectionsList: reqObj} });

};

export const moveQuestionToSectionAction = (reqObj) => async (dispatch) => {
  dispatch(openLoader());
  const data = await callApi.post(`forms/${reqObj.form_id}/move_question_to_section`,reqObj);
  if (data && data.data.success) {
    // dispatch({ type: QUESTION_DATA, payload: data.data.data });
    const newData = formatMultipleMoreThanOneDropdwon(data.data.data);
    dispatch({ type: FORM_LEVEL_QUESTION_DATA, payload: { sectionsList:newData, editable_sections: data.data.meta.editable_sections,is_published:false } });
    ToastMessage(SUCCESS, data.data.messages.success[0]);
    dispatch(closeLoader());
  } else {
    dispatch(closeLoader());
    // ToastMessage(ERROR,data.data.message);
  }
};