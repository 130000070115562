import {
  GET_SUPPLIERS_LIST,
  GET_SUPPLIER_DETAILS,
  GET_VERTICLE_LIST,
  GET_ADMIN_SUPPLIERS_LIST,
  GET_ADMIN_CLIENTS_LIST,
  GET_CLIENT_DETAILS,
  SUPPLIER_PENDING_FORMS_LIST,
  GET_PUBLISHED_FORMS,RESET_PAGINATION
} from "../actions/suppliersAction";
import { CLOSE_LOADER, OPEN_LOADER } from "../actions/appActions";
import { PER_PAGE } from "../../common/constants";
const initialState = {
  supplierList: [],
  supplierDetails: {},
  loader: false,
  verticleList: [],
  adminSupplierList: [],
  adminClientsList: [],
  clientDetails:[],
  supplierPendingForms: [],
  publishedForms:[],
  pagination: {
    per_page: PER_PAGE,
    total_items: 0,
    page: 0,
    total_pages:0
  },

};

export const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUPPLIERS_LIST:
      return {
        ...state,
        supplierList: action.payload,
      };
    case GET_SUPPLIER_DETAILS:
      return {
        ...state,
        supplierDetails: action.payload,
      };
    case OPEN_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case CLOSE_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case GET_VERTICLE_LIST:
      return {
        ...state,
        verticleList: action.payload,
      };
    case GET_ADMIN_SUPPLIERS_LIST:
      return {
        ...state,
        adminSupplierList: action.payload.data,
        pagination:{
          total_items: action.payload.total_items,
          total_pages: action.payload.total_pages,
          page: action.payload.page,
          per_page:action.payload.per_page
        }
      };
    case GET_ADMIN_CLIENTS_LIST:
      return {
        ...state,
        adminClientsList: action.payload.data,
        pagination:{
          total_items: action.payload.total_items,
          total_pages: action.payload.total_pages,
          page: action.payload.page,
          per_page:PER_PAGE
        }
      };
      case GET_CLIENT_DETAILS:
        return {
          ...state,
          clientDetails: action.payload,
        };
        case SUPPLIER_PENDING_FORMS_LIST:
          return {
            ...state,
            supplierPendingForms: action.payload.data,
            pagination:{
              total_items: action.payload.total_items,
              total_pages: action.payload.total_pages,
              page: action.payload.page,
              per_page:action.payload.per_page
            }
          };
        case GET_PUBLISHED_FORMS:
          return {
          ...state,
          publishedForms: action.payload,
        };
        case RESET_PAGINATION:
          return {
          ...state,
          pagination: {
            per_page: PER_PAGE,
            total_items: 0,
            page: 0,
            total_pages:0
         },
        };
    default:
      return state;
  }
};
