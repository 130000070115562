import React from 'react'
import DataTable from '../../components/Tables/DataTable'
import Forms from './Forms';
const SuppliersList = (props) => {
    return (
        <>
            <div className="spacer"></div>
            <DataTable header={props.headerList} data={props.supplierData}/>
           
        </>

    )
}


export default SuppliersList;