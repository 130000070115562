import React, { useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  Modal,
} from "react-bootstrap";
import { useDispatch ,useSelector} from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { inviteClientByAdmin } from "../../redux/actions/userActions";
import Layout from "../../components/Layout";
import PageLoader from "../../common/Loader";
const InviteClient = (props) => {
  const dispatch = useDispatch();
  const { userDetails ,loader } = useSelector((state) => state.user);
  const [show, setShow] = useState(props.isOpen);
  const [sideMenu, setSideMenu] = useState(true);
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  useEffect(() => {
    // setShow(true);
  }, []);
  const handleClose = () => {
    setShow(false);
    props.handleClose();
  };
  const onSubmit = async (data) => {
      await dispatch(inviteClientByAdmin({ ...data}));
    handleClose();
  };
  const onSideMenu = (value) => {
    setSideMenu(value);
  };


  return (
    <div className="page-wrapper">
      <PageLoader loader={loader} >
    <Layout onClick={onSideMenu} />
    <div className={`main-content d-flex flex-column ${sideMenu ? "" : "hide-sidemenu"  }`}>
      {/* <div className="main-content-header">
        <BreadCrumb />
        <div className="Login"> */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Invite Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xl={12}>
              <form
                noValidate
                // validated={validated}
                // onSubmit={e => submitForm(e)}
                // onSubmit={handleSubmit(onSubmit)}
                onSubmit={handleSubmit(onSubmit)}
              >
                <Form.Group type="invalid" controlId="validationCustomUsername">
                  {/* <Form.Label>Email </Form.Label> */}
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text id="inputGroupPrepend">
                        @
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      aria-describedby="inputGroupPrepend"
                      {...register("email")}
                      className={`form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                    />

                    <span className="invalid-feedback">
                      {errors.email?.message}
                    </span>
                  </InputGroup>
                </Form.Group>
                <div className="text-center">
                  <Button
                    variant="btn app-default-button btn btn-primary"
                    type="submit"
                    // onClick={handleClose}
                  >
                    Invite
                  </Button>
                </div>
              </form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
    </PageLoader>
      </div>
      
    // </div>
  // </div> 
    
  );
};

export default InviteClient;
