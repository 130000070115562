import React, { useState } from "react";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import PageLoader from "../../common/Loader";
import DataTable from "../../components/Tables/DataTable";

const SupplierCurrentClients = (props) => {
  const [sideMenu, setSideMenu] = useState(true);
  const onSideMenu = (value) => {
    setSideMenu(value);
  };
  const headerList = [
    { name: "Client Name", key: "clientName" },
    {
      name: "Total Forms",
      key: "totalForms",
    },
    { name: "Percentage Of Completion", key: "percentageOfCompletion" },
    { name: "Percentage Of Progess", key: "percentageOfProgress" },
    { name: "Forms Due Date", key: "formDueDate" },

    { name: "Form Risk Rating", key: "formRiskRating" },
    { name: "Audit Status", key: "auditStatus" },
  ];
  const data = [
    {
      clientName: "Client 1",
      totalForms: 10,
      percentageOfCompletion: "55%",
      percentageOfProgress: "55%",
      formDueDate:"08/04/2021",
      formRiskRating: 2.8,
      auditStatus:"Completed"
    },
    {
      clientName: "Client 1",
      totalForms: 10,
      percentageOfCompletion: "55%",
      percentageOfProgress: "55%",
      formDueDate:"08/04/2021",
      formRiskRating: 5.2,
      auditStatus:"Not Completed"
    },
    {
      clientName: "Client 1",
      totalForms: 10,
      percentageOfCompletion: "55%",
      percentageOfProgress: "55%",
      formDueDate:"08/04/2021",
      formRiskRating: 2.8,
      auditStatus:"Completed"
    },
    {
      clientName: "Client 1",
      totalForms: 10,
      percentageOfCompletion: "55%",
      percentageOfProgress: "55%",
      formDueDate:"08/04/2021",
      formRiskRating: 7.9,
      auditStatus:"Completed"
    },
  ];
  return (
    <div className="page-wrapper">
      <PageLoader loader={false}>
        <Layout onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          <DataTable header={headerList} data={data} />
        </div>
      </PageLoader>
    </div>
  );
};

export default SupplierCurrentClients;
