import "./App.css";
import React, { useState, useEffect } from "react";
import { RouterConfig, UnAuthenticatedRoutes } from "./routes/RouterConfig";
import { ClientRouterConfig } from "./routes/clientRoutes";
import { AdminRouterConfig } from "./routes/adminRoutes";
import { SupplierRouterConfig } from "./routes/supplierRoutes";
import { useSelector, useDispatch } from "react-redux";
import {
  getProfileDetails
} from "./redux/actions/userActions";

import { ToastContainer } from "react-toastify";
import PageLoader from './common/Loader';
import { ADMIN , CLIENT , SUPPLIER } from "./common/constants";
import { useLocation ,useParams} from "react-router";
const App = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const { isAuthenticated , userDetails } = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  useEffect( () => {
    async function fetchProfileDetails(){
      await dispatch(getProfileDetails());
      setIsLoading(false);
      // localStorage.removeItem("emailLink");
      
    }       
    fetchProfileDetails();   
    console.log(location,userDetails)
    if(location.pathname.includes("/supplier/pendingForms/submitAnswers") && !userDetails.role ){
      console.log("Email Link",location.pathname)
      localStorage.setItem("emailLink",location.pathname);
    }else  if(location.pathname.includes("/supplier/pendingForms") && location.pathname.includes("/followup") && !userDetails.role ){
      console.log("Needs Folloup link",location.pathname)
      localStorage.setItem("needsFollowupLink",location.pathname);
    } else {
      localStorage.removeItem("needsFollowupLink")
      localStorage.removeItem("emailLink")
    }

  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const renderSwitch =() =>{
    switch(userDetails.role) {
      case ADMIN:
        return <AdminRouterConfig />
      case CLIENT:
        return <ClientRouterConfig />
      case SUPPLIER:
        return <SupplierRouterConfig />
      default:
        return '';
    }
  }
   
  return (
    <div className="page-wrapper">
      <PageLoader loader={isLoading} >
        {isLoading ? (
          <></>
        ) : (
          <>
            {isAuthenticated ? (
              <div>
                {/* <RouterConfig /> */}
                  {renderSwitch()}
              </div>
            ) : (
              <div> {isAuthenticated ? null : <UnAuthenticatedRoutes />}</div>
            )}
            <ToastContainer />
          </>
        )}
    </PageLoader>
  
    </div>
  );
};

export default App;
