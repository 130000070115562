import React, { useState } from "react";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import PageLoader from "../../common/Loader";
import DataTable from "../../components/Tables/DataTable";

const SupplierAuditStatus = (props) => {
  const [sideMenu, setSideMenu] = useState(true);
  const onSideMenu = (value) => {
    setSideMenu(value);
  };
  const headerList = [
    { name: "Client Name", key: "clientName" },
    { name: "Form Name", key: "formName" },
    {
      name: "Form Type",
      key: "formType",
    },
    { name: "Audit Date", key: "auditDate" },
    {
      name: "Driver",
      key: "driver",
    },
    { name: "Latest Options", key: "latestOptions" },
    { name: "Last Audit Date", key: "lastAuditDate" },
    { name: "Next Action", key: "nextAction" },
    { name: "Next Action Date", key: "nextActionDate" },
    { name: "Next Action Owner", key: "nextActionOwner" }
  ];
  const data = [
    {
      clientName: "Client 1",
      formName: "Form 5",
      formType: "Global",
      auditDate:"08/04/2021",
      driver:"Person 1",
      latestOptions:"Qualified",
      lastAuditDate:"08/04/2021",
      nextAction:"N/A",
      nextActionDate:"08/04/2021",
      nextActionOwner:"Person 1"
    },
    {
      clientName: "Client 1",
      formName: "Form 5",
      formType: "Global",
      auditDate:"08/04/2021",
      driver:"Person 1",
      latestOptions:"Qualified",
      lastAuditDate:"08/04/2021",
      nextAction:"N/A",
      nextActionDate:"08/04/2021",
      nextActionOwner:"Person 1"
    },
    {
      clientName: "Client 1",
      formName: "Form 5",
      formType: "Global",
      auditDate:"08/04/2021",
      driver:"Person 1",
      latestOptions:"Non - Confidence",
      lastAuditDate:"08/04/2021",
      nextAction:"Re-Audit",
      nextActionDate:"08/04/2021",
      nextActionOwner:"Person 1"
    },
    {
      clientName: "Client 1",
      formName: "Form 5",
      formType: "Global",
      auditDate:"08/04/2021",
      driver:"Person 1",
      latestOptions:"Qualified",
      lastAuditDate:"08/04/2021",
      nextAction:"N/A",
      nextActionDate:"08/04/2021",
      nextActionOwner:"Person 1"
    },
    
  ];
  return (
    <div className="page-wrapper">
      <PageLoader loader={false}>
        <Layout onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          <DataTable header={headerList} data={data} />
        </div>
      </PageLoader>
    </div>
  );
};

export default SupplierAuditStatus;
