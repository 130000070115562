import React, { useState, useEffect ,useMemo} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { DEFAULT_PER_PAGE } from "../../common/constants";
const AgGridTable = (props) => {
  const { rowData, columnDefs,perPage } = props;
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
  const gridStyle = useMemo(() => ({ height: '500px', width: '100%' }), []);
  const [gridApi, setGridApi] = useState({});
  const [gridColumnApi, setGridColumnApi] = useState({});
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    setTableData(rowData);
  }, [rowData])
  
  const onGridReady = (params) => {
    // console.log(props.columnDefs,params)
    // setTableData(rowData);
    // if (columnDefs.length <= 10) {
    //   params.api.sizeColumnsToFit();
    // } else {
    //   params.columnApi.autoSizeAllColumns();
    // }
    // window.onresize = () => {
    //   params.api.sizeColumnsToFit();
    // };
    setGridApi(params.api);
    setGridColumnApi(params.columnApi)
    params.api.sizeColumnsToFit();
    props.handleAgGridRef(params)
  };
  return (
      <div style={containerStyle}>
      <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            floatingFilter={true}
            defaultColDef={{
              resizable: true,
            }}
            suppressCellSelection={false}
            columnDefs={columnDefs}
            rowData={tableData}
            pagination={true}
            paginationPageSize={perPage || DEFAULT_PER_PAGE}
            onGridReady={onGridReady}
            enableCellTextSelection={true}
            {...props}
          ></AgGridReact>
        </div>
      </div>
  );
};
export default AgGridTable;