import React, {  useState } from 'react'
import { Button, InputGroup } from 'react-bootstrap'
import Select from 'react-select';


const Filters = (props) => {
  const {role } = props;
      const [selectedSupplier, setSelectedSupplier] = useState([]);
      const [selectedPrimaryVerticle, setSelectedPrimaryVerticle] = useState([]);
      const [selectedSecondaryVerticle, setSelectedSecondaryVerticle] = useState([]);
      const [selectedRevenue, setSelectedRevenue] = useState([]);
      
      const handleChange =async(event)=> {
        const value = event.target.value;
        const name = event.target.name;
        if(name === 'supplierName'){
          setSelectedSupplier([value])
        }
        if(name === 'revenue'){
          setSelectedRevenue([value])
        }
        
      }

      const filterData = () => {
        const reqObj = {
          supplier: [],
          primaryVerticle: [],
          secondaryVerticle: [],
          revenue: []
        };
        selectedSupplier.forEach(item => {
          // reqObj.supplier.push(item.value)
          reqObj.supplier.push(item)
        });
        selectedPrimaryVerticle.forEach(item => {
          reqObj.primaryVerticle.push(item.value)
        });
        selectedSecondaryVerticle.forEach(item => {
          reqObj.secondaryVerticle.push(item.value)
        });
        selectedRevenue.forEach(item => {
          // reqObj.revenue.push(item.value)
          reqObj.revenue.push(item)
        });
        props.filterData(reqObj);
      }
      // const DropdownIndicator = (
      //   props: DropdownIndicatorProps<ColourOption, true>
      // ) => {
      //   return (
      //     <components.DropdownIndicator {...props}>
      //       <span className="fa fa-search"></span>
      //     </components.DropdownIndicator>
      //   );
      // };

      // const filterConfig = {
      //   ignoreCase : true,
      //   ignoreAccents: false,
      //   trim: false,
      //   matchFrom: true
      // };
     
const handlePrimaryVerticle =(data)=>{
  setSelectedPrimaryVerticle([data])
}   
const handleSecondaryVerticle =(data)=>{
  setSelectedSecondaryVerticle([data])
}      
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <div  className="spacer"></div>
                        <div>
                          {/* <Select
                    value={selectedSupplier}
                    onChange={setSelectedSupplier}
                    options={props.suplierOptions}
                    placeholder="Search ex. Supplier Name"
                    isMulti={true}
                    components={{ DropdownIndicator }}
                    isClearable
                    isSearchable
                    filterOption={createFilter(filterConfig)}
                /> */}
                <InputGroup>
                
                <input type="text" className="form-control" name="supplierName" placeholder="Search ex. Client Name" onChange={handleChange} />
                <InputGroup.Prepend>
                          <InputGroup.Text id="inputGroupPrepend">
                            <span className="fa fa-search"></span>
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                </InputGroup>
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <div  className="spacer"></div>
                        <Select
                    value={selectedPrimaryVerticle}
                    onChange={handlePrimaryVerticle}
                    options={props.primaryVerticalOptions}
                    placeholder="Business Vertical"
                    isClearable={true}
                    // isMulti={true}
                />
            </div>
            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <div  className="spacer"></div>
                        <Select
                    value={selectedSecondaryVerticle}
                    onChange={handleSecondaryVerticle}
                    options={props.secondaryVerticalOptions}
                    placeholder="Country"
                    isClearable={true}
                    // isMulti={true}
                />
            </div>
            
            <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
              <div  className="spacer"></div>
                        <Select
                    value={selectedRevenue}
                    onChange={setSelectedRevenue}
                    options={props.revenueOptions}
                    placeholder="State"
                    isMulti={true}
                />
                {/* <input type="text" className="form-control" name="revenue" placeholder="Revenue" onChange={handleChange} /> */}
            </div>
            <div className="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 text-center">
            <div  className="spacer"></div>
            <Button className="btn  app-default-button" onClick={filterData} >Search</Button>
            </div>
        </div>

    )
}


export default Filters;