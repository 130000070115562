import React from 'react'

export default function BasicDetails() {
    return (
        <div>
            <div class="card-body card-body-background-color " >
                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-4 col-form-label">Client Name</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control field-disabled" id="staticEmail" value="" />
                                </div>
                            </div>
                            <div className="spacer"></div>
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-4 col-form-label">Business Vertical</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control field-disabled" id="staticEmail" value="" />
                                </div>
                            </div>
                            <div className="spacer"></div>
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-4 col-form-label">Email Id</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control field-disabled" id="staticEmail" value="" />
                                </div>
                            </div>
                            <div className="spacer"></div>
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-4 col-form-label">No Of Employees</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control field-disabled" id="staticEmail" value="" />
                                </div>
                            </div>
                            <div className="spacer"></div>
                        </div>
                        <div className="col-2 d-none d-lg-block d-xl-block"></div>
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                           
                            <div className="spacer"></div>
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-4 col-form-label">Address</label>
                                <div class="col-sm-8">
                                    <textarea class="form-control field-disabled" id="staticEmail" value="" rows="3" />
                                </div>
                            </div>
                            <div className="spacer"></div>
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-4 col-form-label">Country</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control field-disabled" id="staticEmail" value="" />
                                </div>
                            </div>
                            <div className="spacer"></div>
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-4 col-form-label">State</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control field-disabled" id="staticEmail" value="" />
                                </div>
                            </div>
                            <div className="spacer"></div>
                            <div class="form-group row">
                                <label for="staticEmail" class="col-sm-4 col-form-label">Postal Code</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control field-disabled" id="staticEmail" value="" />
                                </div>
                            </div>
                            <div className="spacer"></div>
                        </div>
                        {/* <div className="col-1"></div> */}
                    </div>
                </div>
            
        </div>
    )
}
