import React, { useEffect, useState } from "react";
// import Forms from './Forms';
// import ManageUsers from './ManageUsers';
import {
  getSupplierDetails,
  getClientDetails,
  updateClientProfileAction,
} from "../../redux/actions/suppliersAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";

import BreadCrumb from "../../components/BreadCrumb";
import Layout from "../../components/Layout";
import Forms from "./Forms";
import { CLIENT } from "../../common/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import Footer from "../Footer";

const ClientDetails = (props) => {
  const { pathname } = useLocation();
  const { userDetails } = useSelector((state) => state.user);
  const isFormEnable = pathname.includes("/suppliers/searchSuppliers/list");
  const dispatch = useDispatch();
  const { supplierDetails, clientDetails } = useSelector(
    (state) => state.supplier
  );
  const [isDisable, setIsDisable] = useState(true);
  const [supplierData, setSupplierData] = useState({});
  const params = useParams();
  const [sideMenu, setSideMenu] = useState(true);
  const onSideMenu = (value) => {
    setSideMenu(value);
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    no_of_employees: Yup.string().required("no_of_employees is required"),
    revenue: Yup.string().nullable(),
    business_vertical: Yup.string().required("Business Vertical is required"),
    address_line1: Yup.string().required("Address is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    zip_code: Yup.string().required("ZipCode is required"),
    // })
  });
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...clientDetails,
    },
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    async function fetchSupplierDetails() {
      await dispatch(getClientDetails({ id: params.id }));
    }
    fetchSupplierDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log(clientDetails);
    //   setSupplierData(clientDetails);
    if (Object.keys(clientDetails).length > 0) {
      setValue("name", clientDetails.name);
      setValue("email", clientDetails.email);
      setValue("no_of_employees", clientDetails.no_of_employees);
      setValue("business_vertical", clientDetails.business_vertical);
      setValue("revenue", clientDetails.revenue);
      setValue("address_line1", clientDetails.address.address_line1);
      setValue("country", clientDetails.address.country);
      setValue("state", clientDetails.address.state);
      setValue("zip_code", clientDetails.address.zip_code);
    }
  }, [clientDetails]);
  const handleEdit = () => {
    setIsDisable(false);
  };
  const onSubmit = async (data) => {
    console.log(data);
    await dispatch(
      updateClientProfileAction({ ...data, ...data.address, id: params.id })
    );
  };
  const onError =(data)=>{
      console.log(data)
  }
  return (
    <>
      <div className="page-wrapper">
        <Layout onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          <div className="row">
            <div className="col-10"></div>
            <div className="col-2 text-right">
              <Button
                className="btn app-default-button btn-sm btn-primary"
                onClick={handleEdit}
              >
                Edit Profile
              </Button>
            </div>
          </div>
          <div className="spacer"></div>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit,onError)}
          >
          <div class="card">
            <div class="card-header card-background-color">Client Profile</div>
            <div class="card-body card-body-background-color ">
              <div className="row">
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                  <div class="form-group row">
                    <label for="staticEmail" class="col-sm-4 col-form-label">
                      Client Name
                    </label>
                    <div class="col-sm-8">
                      <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          }`}
                          id="staticEmail"
                          {...register("name")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.name?.message}
                      </span>
                    </div>
                  </div>
                  <div className="spacer"></div>
                  <div class="form-group row">
                    <label for="staticEmail" class="col-sm-4 col-form-label">
                      Business Vertical
                    </label>
                    <div class="col-sm-8">
                      <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${errors.business_vertical ? "is-invalid" : "" } `}
                          id="staticEmail"
                          {...register("business_vertical")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.business_vertical?.message}
                      </span>
                    </div>
                  </div>
                  <div className="spacer"></div>
                  <div class="form-group row">
                    <label for="staticEmail" class="col-sm-4 col-form-label">
                      Email
                    </label>
                    <div class="col-sm-8">
                      <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${errors.email ? "is-invalid" : "" } `}
                          id="staticEmail"
                          {...register("email")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.email?.message}
                      </span>
                    </div>
                  </div>

                  <div className="spacer"></div>
                  <div class="form-group row">
                    <label for="staticEmail" class="col-sm-4 col-form-label">
                      No Of Employees
                    </label>
                    <div class="col-sm-8">
                      <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${errors.no_of_employees ? "is-invalid" : "" } `}
                          id="staticEmail"
                          {...register("no_of_employees")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.no_of_employees?.message}
                      </span>
                    </div>
                  </div>
                  <div className="spacer"></div>
                </div>
                <div className="col-2 d-none d-lg-block d-xl-block"></div>
                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-5 col-xl-5">
                  <div className="spacer"></div>
                  <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Address
                      </label>
                      <div class="col-sm-8">
                        <textarea
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.address_line1 ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("address_line1")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.address_line1?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Country
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.country ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("country")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.country?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        State
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.state ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("state")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.state?.message}
                      </span>
                      </div>
                    </div>
                    <div className="spacer"></div>
                    <div class="form-group row">
                      <label for="staticEmail" class="col-sm-4 col-form-label">
                        Postal Code
                      </label>
                      <div class="col-sm-8">
                        <input
                          type="text"
                          class={`form-control ${
                            isDisable ? "field-disabled" : null
                          } ${
                            errors.zip_code ? "is-invalid" : ""
                          } `}
                          id="staticEmail"
                          {...register("zip_code")}
                          disabled={isDisable}
                        />
                        <span className="invalid-feedback">
                        {errors.zip_code?.message}
                      </span>
                      </div>
                    </div>
                  <div className="spacer"></div>
                </div>
                {/* <div className="col-1"></div> */}
              </div>
            </div>
          </div>
          <div className="spacer"></div>
          {isDisable ? null : (
              <>
                <div className="row">
                  <div className="col-10"></div>
                  <div className="col-2 text-right">
                    <Button
                      variant="btn app-default-button btn btn-primary"
                      type="submit"
                      // onClick={handleClose}
                    >
                      Update Profile
                    </Button>
                  </div>
                </div>
              </>
            )}
            </form>
        </div>
        <div className="flex-grow-1"></div>
                    <Footer /> 
      </div>

      {/* <ManageUsers /> */}
    </>
  );
};

export default ClientDetails;
