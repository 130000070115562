import { PER_PAGE } from "../../common/constants";
import { CLOSE_LOADER, OPEN_LOADER } from "../actions/appActions";
import {
  CREATE_FORM,
  FORM_LEVEL_QUESTION_DATA,
  FORMS_LIST,
  FORM_DETAILS,
  UPDATE_FORM_DATA,
  RESET_FORM_DATA,
  ADD_QUESTION_FROM_LIBRARY,
  ADMIN_FORMS_LIST,
  RESET_QUESTION_FORM_LIBRARY,
  RESET_FORM_LEVEL_QUESTION_DATA,
  SECTIONS_LIST,
  ADD_CUSTOM_SECTION_TO_FORM,
  GET_SELECTED_SECTIONS_LIST,
  CREATE_SECTION,
  QUESTION_DATA,
  SUBMIT_ANSWERS_VALIDATION,
  GET_SUPPLIERS_LIST,
  RESET_PAGINATION,
  ADMIN_UNPUBLISHED_FORMS_LIST,
  SET_IS_PUBLISHED,
  QUESTION_RE_ORDER
} from "../actions/formLibraryAction";
import { UPDATE_FORM_STATUS } from "../actions/questionLibraryAction";

const initialState = {
  loader: false,
  formData: {},
  formQuestions: {
    // it_security: [],
    // procurement: [],
    // compliance: [],
  },
  formsList: [],
  formDetails: {},
  addQuestionFromLibrary: {},
  adminFormsList: [],
  sectionList: [],
  selectedSectionData: [],
  questionData: {},
  suppliersList: [],
  questionSectionsList: [],
  question_options_list: [],
  pagination: {
    per_page: PER_PAGE,
    total_items: 0,
    page: 0,
    total_pages: 0,
  },
  adminUnpublishedFormsList: [],
  unPublishPagination: {
    per_page: PER_PAGE,
    total_items: 0,
    page: 0,
    total_pages: 0,
  },
  editableSectionsList:[],
  is_published:false,
  formStatus:null,
  status_update_reasonses:[],
  formStatusDate:null,
  supplier_name: null

};

export const formLibraryReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case CLOSE_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case CREATE_FORM:
      return {
        ...state,
        formData: action.payload,
      };
    case FORM_LEVEL_QUESTION_DATA:
      const sectionsData = Object.keys(action.payload.sectionsList).map((obj) => {
        return obj.split(" ").join("");
      });
      return {
        ...state,
        questionSectionsList: sectionsData,
        formQuestions: action.payload.sectionsList,
        editableSectionsList: action.payload.editable_sections,
        is_published: action.payload.is_published,
        formStatus: action.payload.formStatus,
        status_update_reasonses: (action.payload.status_update_reasonses || []),
        // formStatusDate: (action.payload.formStatusDate || null),
        supplier_name: (action.payload.supplier_name || null)
        
      };
    case FORMS_LIST:
      return {
        ...state,
        formsList: action.payload,
        pagination: {
          total_items: action.payload.total_items,
          total_pages: action.payload.total_pages,
          page: action.payload.page,
          per_page: action.payload.per_page,
        },
      };
    case FORM_DETAILS:
      return {
        ...state,
        formDetails: action.payload,
      };
    case UPDATE_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case RESET_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case ADD_QUESTION_FROM_LIBRARY:
      return {
        ...state,
        addQuestionFromLibrary: action.payload,
      };
    case ADMIN_FORMS_LIST:
      return {
        ...state,
        adminFormsList: [...action.payload.data],
        pagination: {
          total_items: action.payload.total_items,
          total_pages: action.payload.total_pages,
          page: action.payload.page,
          per_page: action.payload.per_page,
        },
      };
    case RESET_QUESTION_FORM_LIBRARY:
      return {
        ...state,
        addQuestionFromLibrary: action.payload,
      };
    case RESET_FORM_LEVEL_QUESTION_DATA:
      return {
        ...state,
        formQuestions: action.payload,
        editableSectionsList: [],
        is_published: false,
        formStatus: null,
        status_update_reasonses: [],
        // formStatusDate: (action.payload.formStatusDate || null),
        supplier_name: null
      };
    case SECTIONS_LIST:
      return {
        ...state,
        sectionList: action.payload,
      };
    case ADD_CUSTOM_SECTION_TO_FORM:
      return {
        ...state,
        formQuestions: { ...state.formQuestions, ...action.payload },
      };
    case GET_SELECTED_SECTIONS_LIST:
      return {
        ...state,
        selectedSectionData: action.payload,
      };
    case CREATE_SECTION:
      return {
        ...state,
        formQuestions: { ...state.formQuestions, ...action.payload.sectionName },
        editableSectionsList: action.payload.editable_sections
      };
    case QUESTION_DATA:
      return {
        ...state,
        questionData: action.payload,
        question_options_list:
          Object.keys(action.payload).length > 0
            ? action.payload.questions_options
            : [],
      };
    case SUBMIT_ANSWERS_VALIDATION:
      return {
        ...state,
        formQuestions: { ...action.payload },
      };
    case GET_SUPPLIERS_LIST:
      return {
        ...state,
        suppliersList: action.payload,
      };
    case RESET_PAGINATION:
      return {
        ...state,
        pagination: {
          per_page: PER_PAGE,
          total_items: 0,
          page: 0,
          total_pages: 0,
        },
      };
    case ADMIN_UNPUBLISHED_FORMS_LIST:
      return {
        ...state,
        adminUnpublishedFormsList: action.payload.data,
        unPublishPagination: {
          total_items: action.payload.total_items,
          total_pages: action.payload.total_pages,
          page: action.payload.page,
          per_page: action.payload.per_page,
        },
      };
      case SET_IS_PUBLISHED:
      return {
        ...state,
        is_published: action.payload,
      };
      case QUESTION_RE_ORDER:
      const sectionsList = Object.keys(action.payload.sectionsList).map((obj) => {
        return obj.split(" ").join("");
      });
      return {
        ...state,
        questionSectionsList: sectionsList,
        formQuestions: action.payload.sectionsList,
      };
      case UPDATE_FORM_STATUS:
      return {
        ...state,
         ...action.payload,
      };
    default:
      return state;
  }
};
