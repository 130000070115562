import React, { useState, useEffect } from "react";
import UserAnalyticsBox from "../../common/UserAnlyticsBox";
import formLibrary from "../../assets/img/Group 163@2x.png";
import mysupplier from "../../assets/img/sup2.png";
import searchSupplier from "../../assets/img/cli.png";
import BreadCrumb from "../BreadCrumb";
import Layout from "../Layout";
import {
  fetchDetails,
  fetchAdminDashboardDetails,
  fetchSupplierDetails,
} from "../../redux/actions/dashboardAction";
import { useDispatch, useSelector } from "react-redux";
import PageLoader from "../../common/Loader";
import { ADMIN, CLIENT, SUPPLIER } from "../../common/constants";
import { Link } from "react-router-dom";
import addNew from "../../assets/img/Group 171.png";
import Footer from '../../pages/Footer'
const Dashboard = (props) => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.user);
  const { details, loader, adminDashboardDetails } = useSelector(
    (state) => state.dashboard
  );
  const [sideMenu, setSideMenu] = useState(true);
  const onSideMenu = (value) => {
    setSideMenu(value);
  };

  useEffect(() => {
    async function fetchDashboardDetails() {
      if (userDetails && userDetails.role === CLIENT) {
        await dispatch(fetchDetails({ client_id: userDetails.client_id }));
      }else if (userDetails && userDetails.role === SUPPLIER) {
        await dispatch(fetchSupplierDetails({ supplier_id: userDetails.supplier_id }));
      } else {
        await dispatch(fetchAdminDashboardDetails());
      }
    }

    fetchDashboardDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const {
    form_library_count,
    pending_forms_count,
    suppliers_count,
    total_suppliers,
    sent_forms,
    clients,
    clients_count
  } = details;
  let list = [];
  if (userDetails.role === CLIENT) {
    list = [
      {
        title: "My Suppliers",
        content: "Total number of your suppliers",
        count: suppliers_count || 0,
        iconUrl: mysupplier,
        url: "/client/suppliers",
      },
      {
        title: "Search Suppliers",
        content: "Search for suppliers",
        count: total_suppliers || 0,
        iconUrl: searchSupplier,
        url: "/client/suppliers/searchSuppliers/list",
      },
      {
        title: "Form Library",
        content: "Total number of published forms",
        count: form_library_count || 0,
        iconUrl: formLibrary,
        url: "/client/formLibrary",
      },
      {
        title: "Pending Forms",
        content: "Total number of pending forms",
        count: pending_forms_count || 0,
        iconUrl: mysupplier,
        url: "/client/pendingForms",
      },
    ];
  } else if (userDetails.role === SUPPLIER) {
    list = [
      {
        title: "Clients",
        content: "Total number of connected clients",
        count: clients_count || 0,
        iconUrl: mysupplier,
        url: "/supplier/clients",
      },
      {
        title: "Pending Forms",
        content: "Total number of pending forms",
        count: pending_forms_count || 0,
        iconUrl: mysupplier,
        url: "/supplier/pendingForms",
      },
    ];
  } else {
    list = [
      {
        title: "Clients",
        content: "Total number of clients on the platform",
        count: adminDashboardDetails.clients || 0,
        iconUrl: mysupplier,
        url: "/admin/clients",
      },
      {
        title: "Suppliers",
        content: "Total number of suppliers",
        count: adminDashboardDetails.suppliers || 0,
        iconUrl: searchSupplier,
        url: "/admin/suppliers",
      },
      {
        title: "Form Library",
        content: "Total number of published forms",
        count: adminDashboardDetails.form_library_count || 0,
        iconUrl: formLibrary,
        url: "/admin/formLibrary",
      },
      {
        title: "Sent Forms",
        content: "Total number of sent forms",
        count: adminDashboardDetails.sent_forms || 0,
        iconUrl: mysupplier,
        url: "/admin/sentForms",
      },
    ];
  }

  return (
    <div className="page-wrapper">
      <Layout onClick={onSideMenu} />
      <PageLoader loader={loader}>
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          <div className="row">
            {list.map((obj, key) => (
              <>
                {userDetails.role === ADMIN ? (
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 text-center">
                    <UserAnalyticsBox data={obj} key={key} />
                    <div className="spacer"></div>
                    <div className="spacer"></div>
                  </div>
                ) : (
                  <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 text-center">
                    <UserAnalyticsBox data={obj} key={key} />
                    <div className="spacer"></div>
                    <div className="spacer"></div>
                  </div>
                )}
              </>
            ))}
          </div>
          <div className="flex-grow-1"></div>
                    <Footer /> 
        </div>
      </PageLoader>
      
    </div>
  );
};

export default Dashboard;
