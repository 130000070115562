import React from 'react';
class Footer extends React.Component {
    render(){
        return(
            <footer className="footer mt-2">
                <p>
                    {/* Allrights Reserved &copy; 2021 BRIDLE by <a href="https://envytheme.com" rel="noopener noreferrer" target="_blank">EnvyTheme</a> */}
                     &copy; 2022 UPstream. All Rights Reserved.
                    
                </p>
            </footer>
        )
    }
}

export default Footer;