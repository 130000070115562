export const suplierOptions = [{ value: 'vendor1', label: 'vendor1' },
{ value: 'vendor11', label: 'vendor11' },
{ value: 'vendor12', label: 'vendor12' },];

export const primaryVerticalOptions = [
{ value: 'automobile', label: 'automobile' },
{ value: 'construction', label: 'construction' },
{ value: 'retail', label: 'retail' },];

export const secondaryVerticalOptions = [
    { value: 'automobile', label: 'automobile' },
    { value: 'construction', label: 'construction' },
    { value: 'retail', label: 'retail' },];

export const revenueOptions = [
    { value: '10000', label: '10000' },
    { value: '50000', label: '50000' },
    { value: '100000', label: '100000' },];


    