import React, { useState } from "react";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import PageLoader from "../../common/Loader";
import DataTable from "../../components/Tables/DataTable";

const ComplaintsPercentage = (props) => {
  const [sideMenu, setSideMenu] = useState(true);
  const onSideMenu = (value) => {
    setSideMenu(value);
  };
  const headerList = [
    {
      name: "Vendor Name",
      key: "name",
    },
    { name: "Associated Company", key: "associatedCompany" },
    { name: "Partner Integration Rating", key: "partnerIntegration" },
    { name: "Ranking History", key: "rankingHistroy" },
    { name: "Form Name", key: "formName" },
    { name: "Percentage Of Completion", key: "percentageOfCompletion" },
    { name: "Due Date", key: "dueDate" },
    { name: "Audit", key: "audit",requiredActions: [
      {
        name:"Due Date",
        dataKey: "dueDate",
      },{
        name :"Status",
        dataKey:"audit"
      }
    ]}
  ];
  const nestedHeaders =  ['','','','','','','','Due Date','Status']
  const data = [
    {
      name: "Vendor x",
      associatedCompany: "New Pipeline & Co",
      partnerIntegration: "High",
      rankingHistroy: "8.9",
      formName: "Form 5",
      percentageOfCompletion: "55%",
      dueDate: "21/07/24",
      audit: "Completed",
    },
    {
      name: "Vendor x",
      associatedCompany: "New Pipeline & Co",
      partnerIntegration: "Medium",
      rankingHistroy: "8.9",
      formName: "Form 5",
      percentageOfCompletion: "55%",
      dueDate: "21/07/24",
      audit: "Completed",
    },
    {
      name: "Vendor x",
      associatedCompany: "New Pipeline & Co",
      partnerIntegration: "Low",
      rankingHistroy: "8.9",
      formName: "Form 5",
      percentageOfCompletion: "55%",
      dueDate: "21/07/24",
      audit: "Not Completed",
    },
    {
      name: "Vendor x",
      associatedCompany: "New Pipeline & Co",
      partnerIntegration: "High",
      rankingHistroy: "8.9",
      formName: "Form 5",
      percentageOfCompletion: "55%",
      dueDate: "21/07/24",
      audit: "Completed",
    },
  ];
  return (
    <div className="page-wrapper">
      <PageLoader loader={false}>
        <Layout onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          <DataTable header={headerList} data={data} nestedHeader={nestedHeaders}/>
        </div>
      </PageLoader>
    </div>
  );
};

export default ComplaintsPercentage;
