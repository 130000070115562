import React from "react";
import { Switch, Route ,Redirect  } from "react-router-dom";
import LogIn from "../pages/LogIn";
import SignUp from "../pages/SignUp";
import Suppliers from "../pages/Suppliers";
import FormLibrary from "../pages/FormLibrary";
import FormBuilder from "../pages/FormBuilder";
import QuestionLibrary from "../pages/QuestionLibrary";
import PendingForms from "../pages/PendingForms";
import Reports from "../pages/Reports";
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ForgotPassword/ResetPassword'
import { ROOT, SIGNUP, LOGIN, CLIENT_SUPPLIERS,CLIENT_PENDING_FORMS,CLIENT_QUESTION_LIBRARY,CLIENT_FORM_LIBRARY,CLIENT_REPORTS,
  FORGOT_PASSWORD, RESET_PASSWORD ,DASHBOARD,CLIENT_INVITE_SUPPLIER, CLIENT_FORM_BUILDER, CLIENT_COMPLAINT_PERCENTAGE, CLIENT_FORM_DUE_DATE,
  CLIENT_AUDIT_STATUS, CLIENT_RISK_SOCRE ,CLIENT_FORMS, CLIENT_AUDIT_STATUS_BY_OWNER, SUPPLIER_CLIENTS ,SUPPLIER_CURRENT_CLIENTS,SUPPLIER_PENDING_FORMS,SUPPLIER_AUDIT_STATUS,SUPPLIER_FORMS ,SUPPLIER_REPORTS, ADMIN_FORM_BUILDER, ADMIN_CLIENTS, ADMIN_SUPPLIERS, ADMIN_QUESTION_LIBRARY,ADMIN_FORM_LIBRARY } from "./constants";
import Dashboard from "../components/Dashboard";
import BasicData from "../pages/Suppliers/BasicData";
import InviteSupplier from "../pages/Suppliers/InviteSupplier";
import '../assets/css/style.css';
import '../assets/css/responsive.css';
import ComplaintsPercentage from "../pages/Reports/complaintPercentage";
import FormDueDate from "../pages/Reports/formDueDate";
import RiskScore from "../pages/Reports/riskScore";
import ReportForms from "../pages/Reports/reportForms";
import AuditStatus from "../pages/Reports/auditStatus";
import AuditStatusOwner from "../pages/Reports/auditStatusOwner";
import SupplierCurrentClients from "../pages/Reports/supplierCurrentClients";
import SupplierForms from "../pages/Reports/supplierForms";
import SupplierAuditStatus from "../pages/Reports/supplierAuditStatus";
import Clients from "../pages/Clients";


export const RouterConfig = () => {
  return (
    <div>
      <Switch>
        {/* List all public routes here */}
        <Route exact path={ROOT} component={Dashboard} />
        <Route exact path={DASHBOARD} component={Dashboard} />
        {/* Client Routes Start */}
        <Route exact path={CLIENT_SUPPLIERS} component={Suppliers} />
        <Route exact path="/client/suppliers/:id" component={BasicData} />
        <Route exact path="/client/suppliers/searchSuppliers/list" component={Suppliers} />
        <Route exact path="/client/suppliers/searchSuppliers/list/:id" component={BasicData} />
        <Route exact path={CLIENT_REPORTS} component={ComplaintsPercentage} />
        <Route exact path={CLIENT_PENDING_FORMS} component={PendingForms} />
        <Route exact path={CLIENT_QUESTION_LIBRARY} component={QuestionLibrary} />
        <Route exact path={CLIENT_FORM_LIBRARY} component={FormLibrary} />
        <Route exact path={CLIENT_FORM_BUILDER} component={FormBuilder} />
        <Route exact path={CLIENT_INVITE_SUPPLIER} component={InviteSupplier} />
        <Route exact path={CLIENT_COMPLAINT_PERCENTAGE} component={ComplaintsPercentage} />
        <Route exact path={CLIENT_FORM_DUE_DATE} component={FormDueDate} />
        <Route exact path={CLIENT_RISK_SOCRE} component={RiskScore} />
        <Route exact path={CLIENT_AUDIT_STATUS} component={AuditStatus} />
        <Route exact path={CLIENT_FORMS} component={ReportForms} />
        <Route exact path={CLIENT_AUDIT_STATUS_BY_OWNER} component={AuditStatusOwner} />

        {/* Client route End */}
        
        {/* Supplier routes start */}
        <Route exact path={SUPPLIER_CLIENTS} component={Clients} />
        <Route exact path={SUPPLIER_REPORTS} component={SupplierCurrentClients} />
        <Route exact path={SUPPLIER_PENDING_FORMS} component={PendingForms} />
        <Route exact path={SUPPLIER_CURRENT_CLIENTS} component={SupplierCurrentClients} />
        <Route exact path={SUPPLIER_AUDIT_STATUS} component={SupplierAuditStatus} />
        <Route exact path={SUPPLIER_FORMS} component={SupplierForms} />

        {/* Supplier routes end */}

        {/* Admin Routes Start */}
        <Route exact path={ADMIN_CLIENTS} component={Clients} />
        <Route exact path={ADMIN_SUPPLIERS} component={Suppliers} />
        <Route exact path={ADMIN_QUESTION_LIBRARY} component={QuestionLibrary} />
        <Route exact path={ADMIN_FORM_LIBRARY} component={FormLibrary} />
        <Route exact path={ADMIN_FORM_BUILDER} component={FormBuilder} />

        {/* <Route path={SUPPLIERS}>
          <LogIn />
        </Route> */}
        {/* <Redirect to={DASHBOARD}  /> */}
        <Route exact path="/dashboard">
          <Redirect to={DASHBOARD} />
        </Route>
      </Switch>
    </div>
  );
};


export const UnAuthenticatedRoutes = () => {
  return (
    <div>
      <Switch>
        {/* List all public routes here */}
        <Route exact path={ROOT} component={LogIn} />
        <Route exact path={SIGNUP} component={SignUp} />
        <Route exact path={FORGOT_PASSWORD} component={ForgotPassword} />
        <Route  path={RESET_PASSWORD} component={ResetPassword} />
        <Route path={LOGIN}>
          <LogIn />
        </Route>
        <Redirect to={ROOT}  />
      </Switch>
    </div>
  );
};
