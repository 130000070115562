import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import PageLoader from "../../common/Loader";
import DataTable from "../../components/Tables/DataTable";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { ADMIN, APPROVED, CLIENT, NOT_STARTED, PER_PAGE, SUBMITTED, SUPPLIER, } from "../../common/constants";
import { clientCompletedFormsAction, resetPaginationAction, sentFormsAction, supplierCompletedFormsAction, supplierPendingFormsAction ,SUPPLIER_PENDING_FORMS_LIST,SUPPLIER_SENT_FORMS} from "../../redux/actions/suppliersAction";
import { useDispatch } from "react-redux";
import { Alert } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import AgGridTable from "../../components/Tables/agGridTable";
import Footer from '../../pages/Footer'
const CompletedForms = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [sideMenu, setSideMenu] = useState(true);
  const [headerList, setHeaderList] = useState([]);
  const [agGridRef, setAgGridRef] = useState(null);
  const [rowNode, setRowNode] = useState(null);
  const { loader, supplierPendingForms,pagination } = useSelector((state) => state.supplier);
  const { userDetails } = useSelector((state) => state.user);
  console.log(userDetails)
  const onSideMenu = (value) => {
    setSideMenu(value);
  };
  const editAction = (data)=>{

  }
  const AidRenderComponent = props => {
    return (
      <>{
        props.data ? (
        <NavLink to={`/admin/suppliers/${props.data.vendor_id}/submitAnswers/${props.data.form_id}`}  >
            <span style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              color: "black",
              
            }}>{props.value}</span> </NavLink>
        ) :null 
      }
      </>
      
    );
  };
  const supplierFormRender = props => {
    return (
      <>
      { props.data ? (
        <NavLink to={props.data.status === NOT_STARTED ? `/supplier/pendingForms/submitAnswers/${props.data.form_id}` : `/admin/suppliers/${props.data.vendor_id}/submitAnswers/${props.data.form_id}` }  >
        <span style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          color: "black",
          
        }}>{props.value}</span> </NavLink>
      ) : null }
      </>
      
    );
  };
  const statusRender = props => {
    return (
      <>
        { props.data ? (
           <>{
             props.data.status === SUBMITTED || props.data.status === APPROVED ? (<> <i class="fa fa-circle" aria-hidden="true" style={{color: "#318c2d", fontSize: "10px" }}></i> &nbsp; {props.data.status} </>) : 
             (<> <i class="fa fa-circle" aria-hidden="true" style={{color: "#ff6060", fontSize: "10px" }}></i> &nbsp; {props.data.status} </>)
           }
           </>
         ) : null } 
      </>
    );
  };
  const sendToRenderComponent = props => {
    return (
     <> {
       props.data ? (<> <NavLink to={`/admin/suppliers/${props.data.vendor_id}`}  >
       <span style={{
         display: "flex",
         justifyContent: "space-around",
         alignItems: "center",
         color: "black",
         
       }}>{props.value}</span> </NavLink></>) : null 
     }
     </>
      
    );
  };

  useEffect(async() => {
    dispatch(resetPaginationAction());
    if(userDetails.role === SUPPLIER){
      setHeaderList([
        {
          headerName: "Form Name",
          field: "form_name",
          filter:true,
          sortable:true,
          floatingFilter:true,
          cellRendererFramework: supplierFormRender,
        },
        { headerName: "Sent To", field: "sent_to",filter:true,floatingFilter:true,
        sortable:true, },
        { headerName: "Due Date", field: "due_date" ,floatingFilter:true,
        filter:true,
        sortable:true,},
        { headerName: "Status", field: "status",filter:true,floatingFilter:true,
        sortable:true, cellRendererFramework: statusRender}
      ])
      if(location.pathname === "/supplier/completedForms"){
        await dispatch(supplierCompletedFormsAction({
          supplier_id: userDetails.supplier_id,
          page:1,
          per_page: PER_PAGE
        }))
      }else{
        await dispatch(supplierPendingFormsAction({
          supplier_id: userDetails.supplier_id,
          page:1,
          per_page: PER_PAGE
        }))
      }
      
      
    }
    if(userDetails.role === ADMIN){
      setHeaderList([
        {
          headerName: "Form Name",
          field: "form_name",
          filter:true,
          sortable:true,floatingFilter:true,
          cellRendererFramework: AidRenderComponent },
        { headerName: "Sent To", field: "sent_to",filter:true,floatingFilter:true,
        sortable:true, 
        cellRendererFramework: sendToRenderComponent
      },
        { headerName: "Due Date", field: "due_date" ,filter:true,floatingFilter:true,
        sortable:true,},
        { 
          headerName: "Status", field: "status",filter:true,floatingFilter:true,
        sortable:true, 
        cellRendererFramework: statusRender
      } 
      ])
      await dispatch(sentFormsAction({
        page:1,
        per_page: PER_PAGE
      }))
      
    }
    if(userDetails.role === CLIENT){
      setHeaderList([
        {
          headerName: "Form Name",
          field: "form_name",
          filter:true,
          sortable:true,
          floatingFilter:true,
          cellRendererFramework: supplierFormRender,
        },
        { headerName: "Sent To", field: "sent_to",filter:true,floatingFilter:true,
        sortable:true, },
        { headerName: "Due Date", field: "due_date" ,floatingFilter:true,
        filter:true,
        sortable:true,},
        { headerName: "Status", field: "status",filter:true,floatingFilter:true,
        sortable:true, cellRendererFramework: statusRender}
      ])
      await dispatch(clientCompletedFormsAction({
        client_id: userDetails.client_id,
        page:1,
        per_page: PER_PAGE
      }))
      
    }
  }, []);
  
  const setGridRefData =(params)=>{
    setAgGridRef(params)
  }
  const fetchParamsList =()=>{
    return {
      supplier_id: userDetails.supplier_id
    }
  }


  const renderRoleLelvePendingForms =()=>{
    switch (userDetails.role) {
      case SUPPLIER:
        return (
          <>
          {
          supplierPendingForms.length ? (
            <>
            {/* <DataTable header={headerList} data={supplierPendingForms}/> */}
            <AgGridTable 
                columnDefs={headerList}
                rowData ={supplierPendingForms}
                handleAgGridRef={setGridRefData}
                />
            </>
          ): (<>
                <Alert variant="success">No forms to view</Alert>
            </>)
          
          }
          </>
          )
        break;
        case ADMIN:
        return (
          <>
          {
          supplierPendingForms.length ? (
            <>
            {/* <DataTable header={headerList} data={supplierPendingForms}/> */}
                <AgGridTable 
                  rowData={supplierPendingForms} 
                  columnDefs={headerList} 
                  defaultColDef={{
                    resizable: true,minWidth: 100}}
                  // pagination={pagination}
                  // isPaginationRequired={true}
                  // actionType={SUPPLIER_SENT_FORMS}
                  // fetchParamsList={fetchParamsList}
                  handleAgGridRef={setGridRefData}
              />
            </>
          ): (<>
                <Alert variant="success">No forms to view</Alert>
            </>)
          
          }
          </>
          )
        break;
        case CLIENT:
        return (
          <>
          {
          supplierPendingForms.length ? (
            <>
            {/* <DataTable header={headerList} data={supplierPendingForms}/> */}
            <AgGridTable 
                columnDefs={headerList}
                rowData ={supplierPendingForms}
                handleAgGridRef={setGridRefData}
                />
            </>
          ): (<>
                <Alert variant="success">No forms to view</Alert>
            </>)
          
          }
          </>
          )
        break;
    
      default:
        return (<DataTable header={headerList} data={supplierPendingForms}/>)
        break;
    }
  }
  return (
    <div className="page-wrapper">
      <PageLoader loader={false}>
        <Layout onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          {
              renderRoleLelvePendingForms()
          }
          <div className="flex-grow-1"></div>
                    <Footer /> 
        </div>
      </PageLoader>
    </div>
  );
};

export default CompletedForms;
