import React, { useState } from "react";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import PageLoader from "../../common/Loader";
import DataTable from "../../components/Tables/DataTable";

const AuditStatus = (props) => {
  const [sideMenu, setSideMenu] = useState(true);
  const onSideMenu = (value) => {
    setSideMenu(value);
  };
  const headerList = [
    { name: "Vendor Name", key: "vendorName" },
    {
      name: "Form Type",
      key: "formType",
    },
    { name: "Audit Due Date", key: "auditDueDate" },
    { name: "Driver", key: "driver" },
    { name: "Latest Options", key: "latestOptions" },
    { name: "Latest Audit date", key: "latestAuditDate" },
    { name: "Next Action", key: "nextAction" },
    { name: "Next Action Date", key: "nextActionDate" },
    { name: "Next Action Owner", key: "nextActionOwner" },
  ];
  const data = [
    {
      vendorName: "Vendor X",
      formType: "Global",
      auditDueDate: "12/04/2021",
      driver: "Persion 1",
      latestOptions: "Qualified",
      latestAuditDate: "12/04/2021",
      nextAction:"N/A",
      nextActionDate: "12/04/2021",
      nextActionOwner: "Persion 1",

    },
    {
        vendorName: "Vendor X",
        formType: "Global",
        auditDueDate: "12/04/2021",
        driver: "Persion 1",
        latestOptions: "Qualified",
        latestAuditDate: "12/04/2021",
        nextAction:"N/A",
        nextActionDate: "12/04/2021",
        nextActionOwner: "Persion 1",
  
      },
      {
        vendorName: "Vendor X",
        formType: "Global",
        auditDueDate: "12/04/2021",
        driver: "Persion 1",
        latestOptions: "Non - Conformance",
        latestAuditDate: "12/04/2021",
        nextAction:"N/A",
        nextActionDate: "12/04/2021",
        nextActionOwner: "Persion 1",
  
      },
      {
        vendorName: "Vendor X",
        formType: "Global",
        auditDueDate: "12/04/2021",
        driver: "Persion 1",
        latestOptions: "Qualified",
        latestAuditDate: "12/04/2021",
        nextAction:"N/A",
        nextActionDate: "12/04/2021",
        nextActionOwner: "Persion 1",
  
      },    
  ];
  return (
    <div className="page-wrapper">
      <PageLoader loader={false}>
        <Layout onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          <DataTable header={headerList} data={data} />
        </div>
      </PageLoader>
    </div>
  );
};

export default AuditStatus;
