import React, { useState } from "react";
import Layout from "../../components/Layout";
import BreadCrumb from "../../components/BreadCrumb";
import PageLoader from "../../common/Loader";
import DataTable from "../../components/Tables/DataTable";

const SupplierForms = (props) => {
  const [sideMenu, setSideMenu] = useState(true);
  const onSideMenu = (value) => {
    setSideMenu(value);
  };
  const headerList = [
    { name: "Client Name", key: "clientName" },
    { name: "Form Name", key: "formName" },
    {
      name: "Form Type",
      key: "formType",
    },
    {
      name: "Client With Similary Fonts",
      key: "clientWithSimilarFonts",
    },
    { name: "Percentage Of Completion", key: "percentageOfCompletion" },
    { name: "Audit Due Date", key: "auditDueDate" },
    { name: "Latest Options", key: "latestOptions" },
    { name: "Last Audit Date", key: "lastAuditDate" },
    { name: "Next Audit Date", key: "nextAuditDate" },
    { name: "Owner", key: "owner" }
  ];
  const data = [
    {
      clientName: "Client 1",
      formName: "Form 5",
      formType: "Global",
      clientWithSimilarFonts: 10,
      percentageOfCompletion: "55%",
      auditDueDate:"08/04/2021",
      latestOptions:"Qualified",
      lastAuditDate:"08/04/2021",
      nextAuditDate:"08/04/2021",
      owner:"Person 1"
    },
    {
      clientName: "Client 1",
      formName: "Form 5",
      formType: "Global",
      clientWithSimilarFonts: 10,
      percentageOfCompletion: "55%",
      auditDueDate:"08/04/2021",
      latestOptions:"Non - Confidence",
      lastAuditDate:"08/04/2021",
      nextAuditDate:"08/04/2021",
      owner:"Person 1"
    },
    {
      clientName: "Client 1",
      formName: "Form 5",
      formType: "Global",
      clientWithSimilarFonts: 10,
      percentageOfCompletion: "55%",
      auditDueDate:"08/04/2021",
      latestOptions:"Qualified",
      lastAuditDate:"08/04/2021",
      nextAuditDate:"08/04/2021",
      owner:"Person 1"
    },
    {
      clientName: "Client 1",
      formName: "Form 5",
      formType: "Global",
      clientWithSimilarFonts: 10,
      percentageOfCompletion: "55%",
      auditDueDate:"08/04/2021",
      latestOptions:"Qualified",
      lastAuditDate:"08/04/2021",
      nextAuditDate:"08/04/2021",
      owner:"Person 1"
    },
    
  ];
  return (
    <div className="page-wrapper">
      <PageLoader loader={false}>
        <Layout onClick={onSideMenu} />
        <div
          className={`main-content d-flex flex-column ${
            sideMenu ? "" : "hide-sidemenu"
          }`}
        >
          <div className="main-content-header">
            <BreadCrumb />
          </div>
          <DataTable header={headerList} data={data} />
        </div>
      </PageLoader>
    </div>
  );
};

export default SupplierForms;
